const initialState = {
  waypoints: [],
  active: 0,
};

export default function slideshow(state = initialState, action) {
  if (!action && !action.type) return state; // noop if the action isn't valid.

  switch (action.type) {
    case "REGISTER_WAYPOINTS":
      return Object.assign({}, state, {
        waypoints: state.waypoints.concat(action.waypoints),
      });

    case "UPDATE_ACTIVE_WAYPOINT":
      return Object.assign({}, state, { active: action.active });
  }

  return initialState;
}
