import AnimateLoading from "./AnimateLoading";
import ControlNestedOverlays from "./ControlNestedOverlays";
import ControlSubmenus from "./ControlSubmenus";
import LoadPageContent from "./LoadPageContent";
import OpenNestedOverlay from "./OpenNestedOverlay";
import ReduceHeader from "./ReduceHeader";
import RegisterWaypoints from "./RegisterWaypoints";
import RevealSubmenu from "./RevealSubmenu";
import SetCSSProperties from "./SetCSSProperties";

export default {
  AnimateLoading,
  ControlNestedOverlays,
  ControlSubmenus,
  LoadPageContent,
  OpenNestedOverlay,
  ReduceHeader,
  RegisterWaypoints,
  RevealSubmenu,
  SetCSSProperties,
};
