import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import exhibitionsApiRequest from "../../../api/Exhibitions";
import LoaderAnimation from "../../../components/site/LoaderAnimation";
import Filters from "./Filters";
import YearFilter from "./YearFilter";
import YearPagination from "./YearPagination";
import Thumbnail from "../EntityList/Thumbnail";
import Hero from "../EntityList/Hero";
import Placeholder from "../EntityList/Placeholder";

export default class ExhibitionList extends PureComponent {
  static displayName = "ExhibitionList.Wrapper";

  static propTypes = {
    timeframes: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultTimeframe: PropTypes.object.isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    years: PropTypes.arrayOf(PropTypes.number),
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: true,
      errored: false,
      loadingFromFilters: false,
      activePage: 1,
      exhibitions: [],
      pagination: {},
      currentTimeframe: {},
      currentLocations: [],
      currentYear: null,
    };

    this.scrolling = false;
    this.timeoutId = null;
    this.exhibitionList = React.createRef();
  }

  componentDidMount() {
    // use first category for initial API query
    this.setState({
      currentTimeframe: this.props.defaultTimeframe,
      currentYear: this.props.years[0],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.filtersHaveUpdated(prevState)) {
      this.setItemsFromFilters();
    } else if (prevState.activePage !== this.state.activePage) {
      // run if state.activePage changes (but only if selectedCategories or
      // currentLocations aren't also updated...this prevents these two methods
      // from firing consecutively
      this.setItemsFromPagination();
    }

    // add/remove scroll event listener based on state.pagination
    this.maybeUpdateScrollListener(prevState);
  }

  get showPlaceholder() {
    const { loaded, exhibitions } = this.state;
    return loaded && exhibitions.length === 0;
  }

  filtersHaveUpdated(prevState) {
    return (
      !isEqual(prevState.currentTimeframe, this.state.currentTimeframe) ||
      !isEqual(prevState.currentLocations, this.state.currentLocations) ||
      !isEqual(prevState.currentYear, this.state.currentYear)
    );
  }

  maybeUpdateScrollListener(prevState) {
    if (prevState.pagination.totalPages === this.state.pagination.totalPages)
      return false;

    if (this.state.pagination.totalPages > 1) {
      // add listener when totalPages increases beyond 1
      window.addEventListener("scroll", this.handleScroll);
    } else if (
      prevState.pagination.totalPages > 1 &&
      this.state.pagination.totalPages <= 1
    ) {
      // remove event listener if totalPages was greater than 1 and now isn't
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  updateSelectedFilters = (newTimeframe, newLocations) => {
    this.setState({
      currentTimeframe: newTimeframe,
      currentLocations: newLocations,
    });
  };

  updateYearFilter = (option) => {
    this.setState({
      currentYear: option,
    });
  };

  resetFilters = () => {
    this.setState({
      currentTimeframe: this.props.defaultTimeframe,
      currentLocations: [],
      currentYear: this.props.years[0],
    });
  };

  // construct and fire API request based on changes from filters
  // location filter is multiselect,
  // so query is constructed by combining selections
  setItemsFromFilters() {
    const { currentTimeframe, currentLocations, currentYear, pagination } =
      this.state;
    const params = {
      when: currentTimeframe.name,
      "where[]": this.reduceToArray(currentLocations, "id"),
      year: currentTimeframe.name === "past" ? currentYear : [],
      page: 1, // reset page to 1
    };

    this.setState(
      {
        loaded: false,
        loadingFromFilters: true,
      },
      () => {
        exhibitionsApiRequest(params)
          .catch((error) => {
            console.log(error); // eslint-disable-line
            this.setState({
              loaded: true,
              errored: true,
            });
          })
          .then((results) => {
            return results.json();
          })
          .then((data) => {
            this.setState({
              loaded: true,
              loadingFromFilters: false,
              activePage: 1,
              exhibitions: data.exhibitions,
              pagination: data.pagination,
            });
          });
      }
    );
  }

  // construct and fire API request based on pagination change
  // similar to `setItemsFromFilters`, but because new page items are appended
  // to old items, we set state.exhibitions by merging old and new states
  setItemsFromPagination() {
    const { currentTimeframe, currentLocations, currentYear, activePage } =
      this.state;
    const params = {
      when: currentTimeframe.name,
      "where[]": this.reduceToArray(currentLocations, "id"),
      year: currentYear || [],
      page: activePage,
    };

    this.setState({ loaded: false }, () => {
      exhibitionsApiRequest(params)
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          this.setState({
            loaded: true,
            exhibitions: [...this.state.exhibitions, ...data.exhibitions],
            pagination: data.pagination,
          });
        });
    });
  }

  handleScroll = () => {
    // listen for scroll and trigger pagination
    // when end of exhibitionList is reached
    if (!this.scrolling) {
      window.requestAnimationFrame(() => {
        const exhibitionList = this.exhibitionList.current;
        const box = exhibitionList.getBoundingClientRect();

        if (window.innerHeight >= box.bottom) {
          this.timeoutId = setTimeout(() => {
            this.paginateOnScroll();
          }, 300);
        }

        this.scrolling = false;
      });

      this.scrolling = true;
      clearTimeout(this.timeoutId);
    }
  };

  paginateOnScroll = () => {
    const { currentPage, totalPages } = this.state.pagination;

    // bail if next fetch from API hasn't loaded, or if at end of pages
    if (!this.state.loaded || currentPage === totalPages) return false;

    this.setState({
      activePage: this.state.activePage + 1,
    });
  };

  // helper method to return an array of values from an array of objects
  reduceToArray(objArray, key) {
    return objArray.reduce((accumulator, currentValue) => {
      return [...accumulator, currentValue[key]];
    }, []);
  }

  getPropNameById(prop, id) {
    const locationId = `Castiron\\PSAM\\Models\\Location:${id}`;
    const propObj = find(this.props[prop], ["id", locationId]);
    return propObj ? propObj.name : null;
  }

  renderThumbnail(exhibition, index, listClass) {
    return (
      <Thumbnail
        key={`${exhibition.id}-${index}`}
        entity={exhibition}
        path={"exhibitions"}
        type={"exhibition"}
        showTime={false}
        showYear={false}
        venue={this.getPropNameById("locations", exhibition.location_id)}
        className={`${listClass}__item`}
      />
    );
  }

  renderHero(exhibition, index, listClass) {
    return (
      <Hero
        key={`${exhibition.id}`}
        entity={exhibition}
        path={"exhibitions"}
        type={"exhibition"}
        venue={this.getPropNameById("locations", exhibition.location_id)}
        className={`${listClass}__item l-container`}
      />
    );
  }

  render() {
    const {
      loaded,
      errored,
      loadingFromFilters,
      exhibitions,
      currentTimeframe,
      currentLocations,
      currentYear,
      pagination,
    } = this.state;
    const { timeframes, locations } = this.props;
    const timeframeIsPast = currentTimeframe.id === 2;
    const listClass = !timeframeIsPast
      ? "m-entity-block-list"
      : "m-thumbnail-list";

    return (
      <React.Fragment>
        <Filters
          timeframes={timeframes}
          locations={locations}
          defaultTimeframe={this.props.defaultTimeframe}
          handleOptionListClose={this.updateSelectedFilters}
          handleResetClick={this.resetFilters}
        />
        {timeframeIsPast && (
          <YearFilter
            years={this.props.years}
            currentYear={currentYear}
            handleOptionSelect={this.updateYearFilter}
          />
        )}
        {this.showPlaceholder && <Placeholder type="exhibition" />}
        {errored && <Placeholder type="error" />}
        <ul
          ref={this.exhibitionList}
          className={classNames(listClass, {
            [`${listClass}--loading`]: loadingFromFilters,
          })}
          aria-live="polite"
          aria-busy={!loaded}
        >
          {!loaded && <LoaderAnimation />}
          {exhibitions.map((exhibition, index) => {
            return !timeframeIsPast
              ? this.renderHero(exhibition, index, listClass)
              : this.renderThumbnail(exhibition, index, listClass);
          })}
        </ul>
        {pagination.currentPage < pagination.totalPages && (
          <footer className="m-entity-list-footer">
            <span className="m-entity-list-footer__text">Scroll For More</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width={20}
              height={20}
              className="m-entity-list-footer__icon"
            >
              {/* eslint-disable-next-line max-len */}
              <path d="M17.335 22.905l5.737-5.765 1.869 1.878L16 28l-8.941-8.983 1.869-1.878 5.738 5.767V4h2.669z" />
            </svg>
          </footer>
        )}
        {timeframeIsPast && (
          <YearPagination
            years={this.props.years}
            currentYear={currentYear}
            handleClick={this.updateYearFilter}
          />
        )}
      </React.Fragment>
    );
  }
}
