import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Flickity from "flickity";
import isEmpty from "lodash/isEmpty";

export default class SlideshowCalloutSlideshow extends PureComponent {
  static displayName = "SlideshowCallout.Slideshow";

  static propTypes = {
    slides: PropTypes.array.isRequired,
    height: PropTypes.string,
    active: PropTypes.number,
    onUpdate: PropTypes.func,
  };

  static defaultProps = {
    active: 0,
  };

  componentDidMount() {
    this.imageSlider = new Flickity(this.images, {
      lazyLoad: 2,
      initialIndex: this.props.active,
      prevNextButtons: false,
      pageDots: false,
      adaptiveHeight: !!isEmpty(this.props.height),
      wrapAround: true,
    });

    this.imageSlider.on("change", this.updateActive);

    // if fixed height is specified, set CSS custom properties for slide height
    !isEmpty(this.props.height) && this.setHeightVariables();
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      this.imageSlider.select(this.props.active);
    }
  }

  updateActive = (active) => {
    if (this.props.active !== active) {
      this.props.onUpdate(active);
    }
  };

  setHeightVariables() {
    // set height as `vw` value based on props.height & mobile breakpoint
    // & set max-height from props.height
    // setting both allows slides to gradually scale down on mobile,
    // rather than be fixed to props.height at all times

    const breakpoint = 600;
    const height = this.props.height;

    this.images.style.setProperty(
      "--slideshow-callout-image-max-height",
      `${height}px`
    );
    this.images.style.setProperty(
      "--slideshow-callout-image-height",
      `${(height / breakpoint) * 100}vw`
    );
  }

  renderSlide(slide, index) {
    const height = this.props.height;

    return (
      <img
        key={index}
        className={classNames("m-image", {
          "m-slideshow-callout__image--fixed": !isEmpty(height),
        })}
        data-flickity-lazyload-srcset={`
          ${slide.imageRetina} 2x,
          ${slide.imageDefault} 1x`}
        data-flickity-lazyload-src={slide.imageRetina}
        alt={slide.alt}
      />
    );
  }

  render() {
    return (
      <div
        className="m-slider"
        ref={(s) => {
          this.images = s;
        }}
      >
        {this.props.slides.map((slide, index) => {
          return this.renderSlide(slide, index);
        })}
      </div>
    );
  }
}
