import pageApiRequest from "../../api/Page";
import classNames from "classnames";

import AnimateLoading from "./AnimateLoading";
import Percolator from "../../helpers/Percolator";
import behaviors from "../";
import components from "../../components";

export default class LoadPageContent {
  constructor(el, props, refs) {
    if (!refs.navigation) return null;

    this.state = {
      activeRequests: [],
      currentApiPath: null,
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.wrapperInitClass = refs.contentWrapper.className;
    // Instantiate loading animation
    this.loader = new AnimateLoading(refs.loader);

    this.initHistory();

    this.handleInternal = this.handleInternal.bind(this);
    this.handlePopState = this.handlePopState.bind(this);

    // Bind links to ajax fetch
    this.addListeners();
  }

  /**********
    Getters
  **********/
  get currentUrl() {
    return (
      window.location.pathname + window.location.search + window.location.hash
    );
  }

  get linkElements() {
    return [...this.refs.navigation.querySelectorAll("a")];
  }

  get activeNavElements() {
    const url = this.apiToHref(this.state.currentApiPath);
    const matches = this.linkElements.filter((linkEl) => {
      return url.split("?")[0] === linkEl.getAttribute("href").split("?")[0];
    });

    return matches;
  }

  /**********
    Initial methods
  **********/
  initHistory() {
    // Replace current history with a state object for
    // back button returns to initial place
    const url = this.currentUrl;
    const newState = { url };
    window.history.replaceState(newState, null, url);
  }

  addListeners() {
    this.addLinkListeners();
    this.addWindowListeners();
  }

  addLinkListeners() {
    const cb = (link) => link.addEventListener("click", this.handleLinkClick);
    this.linkElements.forEach(cb);
  }

  addWindowListeners() {
    // Bind popstate to track back button
    window.addEventListener("popstate", this.handlePopState);
    window.addEventListener("ajaxpageload", this.handleInternal);
  }

  handleLinkClick = (event) => {
    event.preventDefault();
    const href = event.target.getAttribute("href");
    const apiPath = this.hrefToApi(href);
    this.load(apiPath);
  };

  handlePopState(event) {
    const current = event.state;
    if (current.url !== undefined) {
      const apiPath = this.hrefToApi(current.url);
      this.load(apiPath, false);
    }
  }

  // Handle page navigation events from inside of content
  handleInternal(event) {
    if (event.detail.href) {
      this.load(this.hrefToApi(event.detail.href));
    }
  }

  /**********
    API-related methods
  **********/
  load(apiPath, updateLocation = true) {
    if (!this.loadable(apiPath)) return;
    this.updateCurrentApiPath(apiPath);

    const onSuccess = (response) => {
      if (!response.ok) throw Error(response);
      this.handleLoadSuccess(requestPromise, response, updateLocation);
    };

    const onError = (error) => {
      this.handleLoadError(error);
    };

    const requestPromise = this.doApiRequest(apiPath, onSuccess, onError)
      .catch((error) => {
        onError(error);
      })
      .then();
    this.state.activeRequests?.push(requestPromise);
    this.maybeStartLoader();
  }

  handleLoadSuccess = (requestPromise, response, updateLocation) => {
    this.removeActiveRequest(requestPromise);
    response.json().then(
      (responseData) => {
        this.renderNewContent(responseData);
        this.updateActiveNavItem();
        this.updateMobileNavHeader();
        this.loadBehaviorsAndComponents();
        if (updateLocation) this.updateLocation();
        this.scrollToContent();
        this.maybeStopLoader();
      },
      (error) => {
        console.log(error, "Unable to parse response json data"); // eslint-disable-line
      }
    );
  };

  doApiRequest(apiPath, onSuccess, onError) {
    return pageApiRequest(apiPath, onSuccess, onError);
  }

  removeActiveRequest(requestPromise) {
    let remove = null;
    this.state.activeRequests.forEach((anActiveRequest, index) => {
      if (anActiveRequest === requestPromise) {
        remove = index;
      }
    });
    if (remove !== null) {
      const removed = this.state.activeRequests.splice(remove, 1);
    }
  }

  clearActiveRequests() {
    this.state.activeRequests = [];
  }

  handleLoadError(error) {
    const contentWrapper = document.querySelector(
      '[data-ref="contentWrapper"]'
    );
    const errorMarkup = `
    <div class="l-container a-bg-white">
      <div class="l-container-wide m-content-rte">
        <header>
          <h2>There was an error loading this page.</h2>
          <p>
            Something went awry while loading this page.
            Please check your network connection,
            then try reloading the page.
          </p>
        </header>
      </div>
    </div>
  `;

    contentWrapper.innerHTML = errorMarkup;
    this.clearActiveRequests();
    this.maybeStopLoader();
    this.loadBehaviorsAndComponents();
    console.log(error); // eslint-disable-line
  }

  /**********
    Callback methods for load success/error
  **********/
  renderNewContent(responseData) {
    this.refs.contentWrapper.innerHTML = responseData.content;
    this.refs.slideshowWrapper.innerHTML = responseData.slideshowBootstrap;
  }

  updateActiveNavItem() {
    const activeEls = this.activeNavElements;
    const baseClass = "m-navigation-tabbed__link";

    this.linkElements.forEach((el) => {
      el.className = classNames(baseClass, {
        [`${baseClass}--active`]: activeEls.includes(el),
        [`${baseClass}--inactive`]: !activeEls.includes(el),
      });
    });
  }

  updateMobileNavHeader() {
    const pageTitle = this.activeNavElements[0].textContent;
    this.refs.dropdownToggle.textContent = pageTitle;
  }

  loadBehaviorsAndComponents() {
    // Rebind behaviors/components when content is loaded
    const percolator = new Percolator();
    percolator.loadBehaviors(behaviors.content, this.refs.contentWrapper);
    percolator.loadComponents(components.content, this.refs.contentWrapper);
    percolator.loadComponents(components.content, this.refs.slideshowWrapper);
  }

  updateLocation() {
    const url = this.apiToHref(this.state.currentApiPath);
    window.history.pushState({ url }, null, url);
  }

  scrollToContent() {
    const box = this.refs.navigation.getBoundingClientRect();

    if (box.top > 0) {
      window.scrollTo({
        top: window.pageYOffset + box.top,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  /**********
    Loading animation
  **********/
  maybeStartLoader = () => {
    if (this.state.activeRequests.length > 0) this.startLoader();
  };

  maybeStopLoader = () => {
    if (this.state.activeRequests.length === 0) this.stopLoader();
  };

  startLoader() {
    this.loader.loading();
    this.refs.contentWrapper.className = classNames(
      this.wrapperInitClass,
      "is-loading"
    );
    this.refs.contentWrapper.setAttribute("aria-busy", "true");
  }

  stopLoader() {
    this.loader.loaded();
    this.refs.contentWrapper.className = classNames(
      this.wrapperInitClass,
      "is-loaded"
    );
    this.refs.contentWrapper.setAttribute("aria-busy", "false");
  }

  /**********
    Helper methods
  **********/
  hrefToApi(href) {
    return `/content${href}`;
  }

  apiToHref(api) {
    return api.substring("/content".length);
  }

  loadable(apiPath) {
    if (this.requestedPathIsCurrentPath(apiPath)) return false;
    return true;
  }

  requestedPathIsCurrentPath(apiPath) {
    return apiPath === this.state.currentApiPath;
  }

  updateCurrentApiPath(apiPath) {
    this.state.currentApiPath = apiPath;
  }
}
