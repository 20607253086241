import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Slideshow from "./Slideshow";
import Caption from "./Caption";

export default class SlideshowCallout extends PureComponent {
  static displayName = "SlideshowCallout.Wrapper";

  static propTypes = {
    layout: PropTypes.string,
    height: PropTypes.string,
    body: PropTypes.string,
    slides: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      active: 0,
    };
  }

  setActive = (active) => {
    this.setState({ active });
  };

  paginatePrev = (event, length) => {
    event.stopPropagation();
    const active = this.state.active;
    const prev = active > 0 ? active - 1 : length - 1;
    this.setState({
      active: prev,
    });
  };

  paginateNext = (event, length) => {
    event.stopPropagation();
    const active = this.state.active;
    const next = active + 1 < length ? active + 1 : 0;
    this.setState({
      active: next,
    });
  };

  renderSVGArrow(rotate) {
    const rotation = rotate === true ? "translate(100, 100) rotate(180)" : "";
    return (
      <svg
        className="m-slideshow-callout__arrow-icon"
        viewBox="0 0 100 100"
        aria-hidden={true}
      >
        <path
          d="M41.615 51.605l6.934 6.9-2.259 2.248L35.488 50 46.29 39.247l2.259
             2.248-6.936 6.901h22.738v3.21z"
          transform={rotation}
        />
      </svg>
    );
  }

  renderPagination() {
    const slides = this.props.slides;
    const active = this.state.active;

    return (
      <div
        className="m-slideshow-callout__pagination"
        aria-label="slideshow controls"
      >
        <button
          aria-label="previous"
          className="m-slideshow-callout__prev-next previous"
          onClick={(event) => {
            this.paginatePrev(event, slides.length);
          }}
        >
          {this.renderSVGArrow()}
        </button>
        <button
          aria-label="next"
          className="m-slideshow-callout__prev-next next"
          onClick={(event) => {
            this.paginateNext(event, slides.length);
          }}
        >
          {this.renderSVGArrow(true)}
        </button>
      </div>
    );
  }

  render() {
    const { layout, height, body, slides } = this.props;
    const { active } = this.state;
    const empty = "\r\n";
    const initCaption =
      slides[active].text !== empty ? slides[active].text : body;

    const slideshowColBaseClass = "m-slideshow-callout__slideshow-column";
    const slideshowColClasses =
      layout === "1"
        ? `${slideshowColBaseClass} ${slideshowColBaseClass}--right`
        : `${slideshowColBaseClass} ${slideshowColBaseClass}--left`;

    return (
      <div className="m-slideshow-callout" aria-label="slider">
        <div className={slideshowColClasses}>
          <Slideshow
            height={height}
            slides={slides}
            active={active}
            onUpdate={this.setActive}
          />
        </div>
        <div className={classNames("m-slideshow-callout__text-column")}>
          {body && (
            <Caption base={initCaption} slides={slides} active={active} />
          )}
          {slides.length > 1 && this.renderPagination()}
        </div>
      </div>
    );
  }
}
