export default class ScrollToAnchor {
  constructor(el, props, refs) {
    this.el = el;

    this.scrollOnLoad();
  }

  scrollOnLoad() {
    if (window.location.hash === "#" + this.el.name) {
      this.el.scrollIntoView({ behavior: "smooth" });
    }
  }
}
