import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";

export default class OpenNestedOverlay extends Sb {
  constructor(el, props, refs) {
    super();

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.props.initOverlayClass = refs.nestedOverlay.className;

    this.state = {
      open: false,
    };

    this.update();
    this.bindToggle();
  }

  update = () => {
    const { nestedOverlay } = this.refs;
    const { initOverlayClass } = this.props;

    nestedOverlay.className = classNames(initOverlayClass, {
      "is-open": this.state.open,
      "is-closed": !this.state.open,
    });
  };

  close() {
    // Called by parent function to close all nested overlays
    this.setState({ open: false });
  }

  bindToggle() {
    const { nestedOverlayToggle } = this.refs;

    [...nestedOverlayToggle].forEach((button) => {
      if (!button) return; // if no submenu (such as user being logged out)
      button.addEventListener("click", this.handleToggle);
    });
  }

  handleToggle = (event) => {
    event.stopPropagation();

    this.setState({ open: !this.state.open });
  };
}
