import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import debounce from "lodash/debounce";

import MembershipTabContent from "./MembershipTabContent";
import Content from "./Content";

export default class ContentTabs extends PureComponent {
  static displayName = "Content.ContentTabs.Wrapper";

  static propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.array,
    contentType: PropTypes.string,
  };

  static defaultProps = {
    className: "",
    contentType: "default",
  };

  constructor(props) {
    super(props);

    this.state = {
      active: 0,
      open: false,
      markerWidth: 0,
      markerLeft: 0,
      contentHeight: "auto",
    };
  }

  get activeItemTitle() {
    const { tabs, contentType } = this.props;
    let tabName;
    contentType === "membership"
      ? (tabName = "$" + tabs[this.state.active].amount)
      : (tabName = tabs[this.state.active].name);
    return tabName;
  }

  componentDidMount() {
    this.buildMarker();

    this.debouncedMarker = debounce(this.buildMarker, 200);
    window.addEventListener("resize", this.debouncedMarker);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.active !== this.state.active) {
      this.buildMarker();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedMarker);
  }

  setActive(event, active) {
    event.preventDefault();
    this.setState({ active });
  }

  buildMarker = () => {
    const b = this["b" + this.state.active];
    const parentLeft = b.parentNode.getBoundingClientRect().left;
    const markerWidth = b.offsetWidth + "px";
    const rect = b.getBoundingClientRect();
    const markerLeft = rect.left - parentLeft + "px";

    this.setState({
      markerWidth,
      markerLeft,
    });
  };

  setContentHeight = () => {
    this.setState({ contentHeight: this.contentEl.clientHeight });
  };

  handleDropdownToggleClick() {
    this.setState({ open: !this.state.open });
  }

  renderDesktopMenu() {
    const tabs = this.props.tabs;
    const tabType = this.props.contentType;
    const blockClass = "m-tabbed-content";

    return (
      <nav
        className={
          `${blockClass}__tab-nav-outer ` +
          `${blockClass}__tab-nav-outer--hide-mobile`
        }
      >
        <ul
          className={
            `${blockClass}__tab-nav-inner ` +
            `${blockClass}__tab-nav-inner--even-tabs`
          }
        >
          {tabs.map((tab, index) => {
            let tabName;
            let navLinkClass;
            tabType === "membership"
              ? (tabName = "$" + tab.amount)
              : (tabName = tab.name);
            return (
              <li
                key={"tab-" + index}
                className={
                  `${blockClass}__tab-nav-header ` +
                  `${blockClass}__tab-nav-header--even-tabs`
                }
                ref={(b) => {
                  this["b" + index] = b;
                }}
              >
                <button
                  className={classNames({
                    "m-tabbed-content__tab-nav-header-link":
                      tabType === "membership",
                    "m-tabbed-content__tab-nav-header-link--inactive":
                      index !== this.state.active,
                    "m-tabbed-content__tab-nav-header-link--active":
                      index === this.state.active,
                  })}
                  onClick={(event) => {
                    this.setActive(event, index);
                  }}
                >
                  {tabName}
                </button>
              </li>
            );
          })}
        </ul>
        <div
          className={`${blockClass}__tab-marker`}
          style={{
            width: this.state.markerWidth,
            left: this.state.markerLeft,
          }}
          aria-hidden={true}
        />
      </nav>
    );
  }

  renderMobileMenu() {
    const tabs = this.props.tabs;
    const { open } = this.state;
    const tabType = this.props.contentType;
    const labelId = "page-navigation-listbox-label";
    const buttonId = "page-navigation-listbox-toggle";
    const blockClass = "m-navigation-tabbed";

    return (
      <div className={`${blockClass}__mobile-wrapper`}>
        <span id="page-navigation-listbox-label" className="a-hidden">
          Select a tab to which to navigate
        </span>
        <button
          aria-haspopup="listbox"
          aria-labelledby={`${labelId} ${buttonId}`}
          id={buttonId}
          className={`${blockClass}__toggle ${blockClass}__toggle-border`}
          onClick={(event) => this.handleDropdownToggleClick()}
        >
          {this.activeItemTitle}
        </button>
        <div
          role="listbox"
          aria-labelledby={labelId}
          aria-hidden={!open}
          aria-expanded={open}
          className={classNames(`${blockClass}__dropdown`, {
            "is-closed": !open,
            "is-open": open,
          })}
        >
          <ul className={`${blockClass}__list ${blockClass}__list--mobile`}>
            {this.props.tabs.map((tab, index) => {
              return this.renderItem(tab, index);
            })}
          </ul>
        </div>
      </div>
    );
  }

  renderItem(tab, index) {
    const active = this.state.active;
    const baseClass = "m-navigation-tabbed__link";
    let tabName;
    this.props.contentType === "membership"
      ? (tabName = "$" + tab.amount)
      : (tabName = tab.name);

    const linkClass = classNames(baseClass, {
      [`${baseClass}--active`]: index === active,
      [`${baseClass}--inactive`]: index !== active,
    });

    return (
      <li key={index} className="m-navigation-tabbed__item">
        <button
          className={linkClass}
          onClick={(event) => {
            this.setActive(event, index);
          }}
        >
          {tabName}
        </button>
      </li>
    );
  }

  renderPlaceholders(Template) {
    const tabs = this.props.tabs;

    return (
      <div
        className="m-tabbed-content__placeholder-list"
        aria-hidden="true"
        style={{ width: tabs.length + "00%" }}
      >
        {tabs.map((tab, index) => {
          return (
            <Template
              key={index}
              tab={tab}
              addClass="m-tabbed-content__placeholder"
            />
          );
        })}
      </div>
    );
  }

  renderPanels(Template) {
    return (
      <div className="m-tabbed-content__panels">
        {this.renderPlaceholders(Template)}
        <Template tab={this.props.tabs[this.state.active]} />
      </div>
    );
  }

  render() {
    const contentComponents = {
      membership: MembershipTabContent,
      default: Content,
    };

    const Template = contentComponents[this.props.contentType];

    return (
      <div className={`tab-content ${this.props.className}`}>
        {this.renderDesktopMenu()}
        {this.renderMobileMenu()}
        {this.renderPanels(Template)}
      </div>
    );
  }
}
