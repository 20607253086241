/*
Creates and updates the value of a CSS Custom Property for `vh` units
to ensure consistent measurements of vh across browsers

https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
*/
import Sb from "../abstract/StatefulBehavior";
import debounce from "lodash/debounce";

export default class SetCSSProperties extends Sb {
  constructor(el, props, refs) {
    super();

    this.refs = refs;
    this.state = {
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    };

    this.update();
    this.bindResize();
  }

  update = () => {
    const vw = this.state.width * 0.01;
    const vh = this.state.height * 0.01;

    const headerEl = document.querySelector('[data-behavior="ReduceHeader"]');
    const headerHeight = headerEl.getBoundingClientRect().height;

    const contentWrapper = this.refs.contentWrapper;

    document.documentElement.style.setProperty("--vw", `${vw}px`);
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerHeight}px`
    );
    document.documentElement.style.setProperty(
      "--content-wrapper-top",
      `${contentWrapper.offsetTop}px`
    );
  };

  bindResize() {
    // Set overlay to closed state if at wider viewport width
    const debouncedClientSize = debounce(() => {
      this.setState({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    }, 200);

    window.addEventListener("resize", debouncedClientSize);
  }
}
