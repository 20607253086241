import Percolator from "../../helpers/Percolator";
import Sb from "../abstract/StatefulBehavior";
import RevealSubmenu from "./RevealSubmenu";

export default class ControlSubmenus extends Sb {
  constructor(el, props, refs) {
    super(props);

    this.state = {
      active: null,
      hovering: false,
      height: null,
    };

    this.refs = refs;

    const percolator = new Percolator();

    const revealArray = Array.isArray(refs.reveal)
      ? refs.reveal
      : [refs.reveal];

    // Instantiate (and track) the reveals with callbacks
    this.reveals = [...revealArray].map((reveal, index) => {
      return percolator.loadBehavior(reveal, RevealSubmenu, {
        id: index,
        onHoverIn: this.handleHoverIn,
        onHoverOut: this.handleHoverOut,
      });
    });

    this.update();
  }

  update = () => {
    const { submenuBg } = this.refs;
    const { hovering, height } = this.state;
    const visibleClass = "is-visible";
    const invisibleClass = "is-invisible";

    if (hovering) {
      submenuBg.classList.add(visibleClass);
      submenuBg.classList.remove(invisibleClass);
    } else {
      submenuBg.classList.add(invisibleClass);
      submenuBg.classList.remove(visibleClass);
    }

    hovering &&
      Object.assign(this.refs.submenuBg.style, { height: `${height}px` });
  };

  // run the "close" function on all but the active hover
  // and update state to track hovering and active submenu
  handleHoverIn = (active) => {
    this.reveals.forEach((reveal, index) => {
      if (active !== index) {
        reveal.close();
      } else {
        const submenuBox = this.refs.submenu[active].getBoundingClientRect();
        this.setState({
          active: index,
          hovering: true,
          height: submenuBox.height,
        });
      }
    });
  };

  // on hoverOut, test if state.active has changed to a new submenu
  // if it hasn't, then safe to update state.hovering to `false`
  handleHoverOut = (active) => {
    if (active === this.state.active) {
      this.setState({
        active: null,
        hovering: false,
      });
    }
  };
}
