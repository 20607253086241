/*
Arrange Grid Blocks:
Creates a masonry-style layout for `.m-columns` blocks on non-mobile screens.

Works by calculating the height of each grid child as a factor of rows,
then assigning that child's `grid-row-end` property a value of `span ${rows}`.

Calculates initially when all DOM content is loaded, then again on window
resize (so long as the window is larger than mobile size, in which case the
grid layout is reset to simply stack vertically).

https://codepen.io/persianturtle/pen/pLNEXm
*/

import Sb from "../abstract/StatefulBehavior";
import debounce from "lodash/debounce";
import imagesLoaded from "imagesloaded";

export default class ArrangeGridBlocks extends Sb {
  constructor(el, props, refs) {
    super();

    if (!refs.block.length || refs.block.length <= 1) return null;

    this.el = el;
    this.refs = refs;
    this.props = props;

    props.rowHeight = this.getStyleValue(el, "grid-auto-rows");
    props.rowGap = this.getStyleValue(el, "grid-row-gap");

    imagesLoaded(this.el, () => {
      !this.isMobileWidth() && this.resizeBlocks();
      this.bindWindowResize();
    });
  }

  bindWindowResize() {
    const debouncedResize = debounce(() => {
      !this.isMobileWidth() ? this.resizeBlocks() : this.resetGrid();
    }, 200);

    window.addEventListener("resize", debouncedResize);
  }

  resizeBlocks = () => {
    const rowHeight = 1;
    const rowGap = this.props.rowGap;

    this.el.style.gridAutoRows = "auto";
    this.el.style.alignItems = "self-start";

    [...this.refs.block].forEach((block) => {
      const rowEnd = Math.ceil(
        (block.clientHeight + rowGap) / (rowHeight + rowGap)
      );
      block.style.gridRowEnd = `span ${rowEnd}`;
    });

    this.el.removeAttribute("style");
  };

  resetGrid = () => {
    [...this.refs.block].forEach((block) => {
      block.style.gridRowEnd = `auto`;
    });
  };

  isMobileWidth() {
    const mobileWidth = 600;

    return window.innerWidth < mobileWidth;
  }

  getStyleValue(element, style) {
    return parseInt(window.getComputedStyle(element).getPropertyValue(style));
  }
}
