import Sb from "../abstract/StatefulBehavior";

export default class ToggleDropdown extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      open: false,
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.timeoutId = null;

    this.update();
    this.bindEvents();
  }

  get focusable() {
    return Array.from(this.refs.dropdown.querySelectorAll("[href], button"));
  }

  update = () => {
    const dropdown = this.refs.dropdown;
    const open = this.state.open;

    if (open) {
      dropdown.classList.add("is-open");
      dropdown.classList.remove("is-closed");
    } else {
      dropdown.classList.add("is-closed");
      dropdown.classList.remove("is-open");
    }

    this.refs.dropdownToggle.setAttribute(
      "aria-pressed",
      open ? "true" : "false"
    );
    this.refs.dropdown.setAttribute("aria-expanded", open ? "true" : "false");
    this.refs.dropdown.setAttribute("aria-hidden", !open ? "true" : "false");

    // set a max-height for the dropdown based on avaialable space
    // this animates opening of dropdown, and also allows for scrolling if
    // overflow exists
    this.setDropdownHeight();
  };

  bindEvents() {
    const { dropdown, dropdownToggle } = this.refs;

    dropdownToggle.addEventListener("click", this.toggleDropdown);

    dropdown.addEventListener("focusin", this.handleFocusIn);
    dropdown.addEventListener("focusout", this.handleFocusOut);
  }

  toggleDropdown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    !this.state.open ? this.openDropdown() : this.closeDropdown();
  };

  openDropdown() {
    this.setState({ open: true });
    window.addEventListener("keyup", this.handleKeyUp);
    this.focusable[0].focus();
  }

  closeDropdown() {
    this.setState({ open: false });
    window.removeEventListener("keyup", this.handleKeyUp);
  }

  handleFocusIn = () => {
    clearTimeout(this.timeoutId);
  };

  handleFocusOut = () => {
    this.timeoutId = setTimeout(() => {
      this.closeDropdown();
    }, 100);
  };

  handleKeyUp = (event) => {
    event.preventDefault();

    switch (event.key) {
      case "Esc": // IE/Edge specific value
      case "Escape":
        this.closeDropdown();
        break;
      case "ArrowUp":
        this.handleArrowKeyUp();
        break;
      case "ArrowDown":
        this.handleArrowKeyDown();
        break;
    }
  };

  handleArrowKeyUp() {
    const focusedItem = document.activeElement;
    const focusableIndex = this.focusable.indexOf(focusedItem);

    focusableIndex === 0
      ? this.focusable[this.focusable.length - 1].focus()
      : this.focusable[focusableIndex - 1].focus();
  }

  handleArrowKeyDown() {
    const focusedItem = document.activeElement;
    const focusableIndex = this.focusable.indexOf(focusedItem);

    focusableIndex === this.focusable.length - 1
      ? this.focusable[0].focus()
      : this.focusable[focusableIndex + 1].focus();
  }

  setDropdownHeight = () => {
    const box = this.refs.dropdown.getBoundingClientRect();
    const available = window.innerHeight - box.top;

    Object.assign(this.refs.dropdown.style, {
      maxHeight: this.state.open ? `${available}px` : "0px",
    });
  };
}
