import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Flickity from "flickity";
import has from "lodash/has";

export default class Slideshow extends PureComponent {
  static displayName = "Slideshow";

  static propTypes = {
    slides: PropTypes.array.isRequired,
    active: PropTypes.number.isRequired,
    onUpdate: PropTypes.func,
    onClose: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.slideshowRef = props.slideshowRef ?? React.createRef();

    // Prevent Flickity from jumping on focus
    // https://github.com/metafizzy/flickity/issues/325
    const touchStartEvents = {
      touchstart: true,
      MSPointerDown: true,
    };

    const focusNodes = {
      INPUT: true,
      SELECT: true,
    };

    Flickity.prototype.pointerDownFocus = function (event) {
      // focus element, if not touch, and its not an input or select
      if (
        !this.options.accessibility ||
        touchStartEvents[event.type] ||
        focusNodes[event.target.nodeName]
      ) {
        return;
      }
      // hack to fix scroll jump after focus, #76
      const scrollElem = this.options.scrollElement || window;
      const scrollProp = this.options.scrollElement
        ? "scrollTop"
        : "pageYOffset";
      const prevScrollY = scrollElem[scrollProp];

      this.element.focus();
      // reset scroll position after focus
      if (scrollElem[scrollProp] !== prevScrollY) {
        if (this.options.scrollElement) {
          scrollElem.scrollTop = prevScrollY;
        } else {
          scrollElem.scrollTo(scrollElem.pageXOffset, prevScrollY);
        }
      }
    };
  }

  componentDidMount() {
    this.imageSlider = new Flickity(this.images, {
      lazyLoad: 2,
      initialIndex: this.props.active,
      prevNextButtons: false,
      pageDots: false,
      scrollElement: this.slideshowRef?.current,
    });

    this.imageSlider.focus();

    // Update parent state when slider changes
    this.imageSlider.on("change", this.updateActive);
    this.imageSlider.on("staticClick", this.clickActive);

    window.addEventListener("keyup", this.closeOnEscape);
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      this.imageSlider.select(this.props.active);

      const timeoutId = setTimeout(() => {
        this.slideshowRef?.current?.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 300);
    }
  }

  updateActive = (active) => {
    if (this.props.active !== active) {
      this.props.onUpdate(active);
    }
  };

  clickActive = (event, pointer, cellElement, cellIndex) => {
    this.imageSlider.select(cellIndex);
  };

  closeOnEscape = (event) => {
    if (
      event.key === "Escape" ||
      event.key === "Esc" ||
      event.keyCode === 27 ||
      event.which === 27
    ) {
      this.props.onClose();
    }
  };

  renderCloseButton() {
    return (
      <button
        className="m-overlay__close m-overlay__close--dark"
        onClick={this.props.onClose}
      >
        <span className="a-hidden">Close overlay</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          className="m-overlay__icon a-icon"
          aria-hidden={true}
        >
          <path
            fillRule="evenodd"
            d="M30.675 28.651L18.024 16 30.675 3.349l-2.024-2.024L16 13.976
               3.349 1.325 1.325 3.349 13.976 16 1.325 28.651l2.024 2.024L16
               18.024l12.651 12.651 2.024-2.024z"
          />
        </svg>
      </button>
    );
  }

  renderCaption(slide) {
    const { media, dimensions, collection, details, copyright, photoCredit } =
      slide;
    const collectionTitle = collection ? collection.title + " Collection" : "";
    const metadataArray = [
      media,
      dimensions,
      collectionTitle,
      details,
      copyright,
      photoCredit,
    ];
    // remove any empty strings and join array
    const caption = metadataArray.filter(Boolean).join(". ");

    return <p className="m-slideshow-figure__caption">{`${caption}.`}</p>;
  }

  renderSlide(slide, index) {
    return (
      <div className="m-slider__child" key={index}>
        <figure className="m-slideshow-figure">
          <img
            data-flickity-lazyload-srcset={`
              ${slide.imageLarge} 1576w,
              ${slide.imageSmall} 788w`}
            data-flickity-lazyload-src={slide.imageSmall}
            sizes="(max-width: 928px) 80vw, 1200px"
            className="m-slideshow-figure__image"
            alt=""
          />
          <figcaption>
            <div className="m-slideshow-figure__heading">
              {has(slide, "artist.fullName")
                ? `${slide.artist.fullName}, `
                : ""}
              <em>{`${slide.title}, `}</em>
              {`${slide.year}`}
            </div>
            {this.renderCaption(slide)}
          </figcaption>
        </figure>
      </div>
    );
  }

  renderSlider(slides) {
    return (
      <div
        className="m-slider m-slider--wide m-slider--full"
        ref={(s) => {
          this.images = s;
        }}
      >
        {slides.map((slide, index) => {
          return this.renderSlide(slide, index);
        })}
      </div>
    );
  }

  render() {
    const { slides, active } = this.props;
    const headingClasses =
      "t-heading-tertiary l-pad-top-small l-pad-bottom-x-small";

    return (
      <div
        ref={this.slideshowRef}
        className={classNames(
          "m-overlay",
          "m-overlay--full",
          "m-overlay--light",
          "m-overlay--overflow",
          "is-open"
        )}
      >
        <div className="m-overlay__wrapper">
          <div className="m-overlay__content-overflow">
            <header className="t-align-center">
              <div className={headingClasses} aria-live="polite">
                {`${active + 1} of ${slides.length}`}
              </div>
            </header>
            {this.renderSlider(slides)}
          </div>
          {this.renderCloseButton()}
        </div>
      </div>
    );
  }
}
