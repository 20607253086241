import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import SanitizedHTML from "react-sanitized-html";

export default class DisplayName extends PureComponent {
  static displayName = "Content.DisplayName";

  static propTypes = {
    name: PropTypes.string,
    displayName: PropTypes.string,
  };

  render() {
    const { name, displayName } = this.props;

    return displayName ? (
      <SanitizedHTML
        allowedTags={["span"]}
        allowedAttributes={{ span: ["style"] }}
        html={displayName}
      />
    ) : name ? (
      <SanitizedHTML html={name} />
    ) : null;
  }
}
