import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class TabContent extends PureComponent {
  static displayName = "Content.ContentTabs.Content";

  static propTypes = {
    tab: PropTypes.object,
    addClass: PropTypes.string,
  };

  render() {
    const { tab, addClass } = this.props;
    const baseClass = "level";

    return (
      <div
        className={classNames(baseClass, addClass)}
        dangerouslySetInnerHTML={{
          __html: tab.body,
        }}
        aria-live="polite"
      />
    );
  }
}
