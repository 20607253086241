// SPEKTRIX DOCS: https://integrate.spektrix.com/docs/Introduction
import keysIn from "lodash/keysIn";
import classNames from "classnames";
import Sb from "../abstract/StatefulBehavior";
import AnimateLoading from "../site/AnimateLoading";
import urlParse from "url-parse";

export default class SwapPageContentWithIframe extends Sb {
  constructor(el, props, refs) {
    super();

    this.el = el;
    this.props = props;
    this.refs = refs;
    if (!props.id) return null;
    if (!props.baseurl) return null;

    this.state = {
      iframeReady: false,
    };

    this.baseUrlOrigin = urlParse(props.baseurl).origin;

    this.loader = new AnimateLoading(
      this.bodyNode.querySelector(`[data-ref="loader"]`)
    );
    this.pageContentNodeInitClass = this.pageContentNode.className;

    this.addSpektrixScript();
    this.bindClick();
    this.bindResizeMessage();
  }

  get bodyNode() {
    return document.querySelector("body");
  }

  get pageContentNode() {
    return document.getElementById("page-content");
  }

  get spektrixUrl() {
    return this.props.baseurl + "/website";
  }

  get scriptSrc() {
    return `${this.spektrixUrl}/scripts/integrate.js`;
  }

  get iframeSrc() {
    // eslint-disable-next-line
    return `${this.spektrixUrl}/ChooseSeats.aspx?resize=true&WebInstanceId=${this.props.id}`;
  }

  get iframeAttributes() {
    return {
      name: "SpektrixIFrame",
      id: "SpektrixIFrame",
      frameborder: 0,
      language: "javascript",
      src: this.iframeSrc,
      style: `width:100%;height:1000px;opacity:0;transition:opacity 1s;`,
    };
  }

  get iframeEl() {
    const el = document.createElement("iframe");
    const attrKeys = keysIn(this.iframeAttributes);
    attrKeys.forEach((key) => el.setAttribute(key, this.iframeAttributes[key]));
    return el;
  }

  get iframeContainerEl() {
    const el = document.createElement("div");
    el.classList.add("l-container", "l-no-top-padding", "l-no-bottom-padding");
    return el;
  }

  get scriptEl() {
    const el = document.createElement("script");
    el.setAttribute("type", "text/javascript");
    el.setAttribute("src", this.scriptSrc);
    el.setAttribute("async", true);
    return el;
  }

  removePageContent() {
    while (this.pageContentNode.firstChild) {
      this.pageContentNode.removeChild(this.pageContentNode.firstChild);
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  addSpektrixScript() {
    if (this.bodyNode.lastChild.src === this.scriptSrc) return null;
    this.bodyNode.appendChild(this.scriptEl);
  }

  addIframe() {
    const containerEl = this.iframeContainerEl;
    containerEl.appendChild(this.iframeEl);
    this.pageContentNode.appendChild(containerEl);
  }

  startLoader() {
    this.loader.loading();
    this.pageContentNode.classList.toggle = classNames(
      this.pageContentNodeInitClass,
      "is-loading"
    );
    this.pageContentNode.setAttribute("aria-busy", "true");
  }

  stopLoader() {
    this.loader.loaded();
    this.pageContentNode.className = classNames(
      this.pageContentNodeInitClass,
      "is-loaded"
    );
    this.pageContentNode.setAttribute("aria-busy", "false");
  }

  revealIframe = () => {
    setTimeout(() => {
      const iframe = this.pageContentNode.querySelector("#SpektrixIFrame");
      this.stopLoader();
      Object.assign(iframe.style, { opacity: 1 });
    }, 1500);
  };

  handleClick = () => {
    this.removePageContent();
    this.scrollToTop();
    this.startLoader();
    this.addIframe();
  };

  handleResizeMessage = (event) => {
    if (
      this.state.iframeLoaded ||
      typeof event.data !== "string" ||
      event.origin !== this.baseUrlOrigin ||
      !event.data.startsWith("resize")
    )
      return;
    this.setState({ iframeLoaded: true }, this.revealIframe);
  };

  bindClick() {
    this.el.addEventListener("click", this.handleClick);
  }

  bindResizeMessage() {
    window.addEventListener("message", this.handleResizeMessage);
  }
}
