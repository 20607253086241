import classNames from "classnames";
import Sb from "../abstract/StatefulBehavior";
import hoverintent from "hoverintent";

export default class RevealSubmenu extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      hovering: false,
      animating: false,
    };

    // Create a class variable
    // To track for timeouts
    this.outTimer = "";

    this.refs = refs;
    this.props = props;
    this.el = el;

    this.props.initActivatorClass = refs.activator.className;
    this.props.initMenuClass = refs.submenu.className;

    this.bindEvents();
  }

  update = () => {
    const { activator, submenu } = this.refs;
    const { initActivatorClass, initMenuClass } = this.props;
    const { hovering, animating } = this.state;

    activator.className = classNames(initActivatorClass, {
      "is-hovering": hovering,
    });

    submenu.className = classNames(initMenuClass, {
      "is-open": hovering,
      "is-closed": !hovering,
    });

    // Direct behavior of parent of submenus based on hover state
    hovering && this.props.onHoverIn(this.props.id);
  };

  hoverIn = (animate = false) => {
    // Prevent timeout from setting false
    const animating = !this.state.hovering && animate;
    clearTimeout(this.outTimer);
    this.setState({
      hovering: true,
      animating,
    });
  };

  hoverOut = (delay = true) => {
    // Ensure timout doesn't run twice
    clearTimeout(this.outTimer);

    if (delay) {
      this.outTimer = setTimeout(() => {
        this.setState(
          {
            hovering: false,
          },
          this.props.onHoverOut(this.props.id)
        );
      }, 300);
    } else {
      this.setState(
        {
          hovering: false,
        },
        this.props.onHoverOut(this.props.id)
      );
    }
  };

  close() {
    // Called by parent function to close all
    // but the active dropdown
    // Ensure that timer doesn't run after close
    clearTimeout(this.outTimer);
    this.setState({ hovering: false });
  }

  bindEvents() {
    const { activator, submenu } = this.refs;

    // show animation if not already hovering
    // set hovering to true
    hoverintent(
      activator,
      () => {
        this.hoverIn();
      },
      () => {
        return null;
      }
    ).options({
      interval: 35,
      handleFocus: true,
    });

    // Set a timeout to close menu
    activator.addEventListener("mouseleave", (e) => {
      this.hoverOut();
    });
    activator.addEventListener("blur", (e) => {
      this.hoverOut();
    });

    // open and close submenu on touch
    activator.addEventListener(
      "touchstart",
      (e) => {
        e.preventDefault();
        this.state.hovering ? this.hoverOut() : this.hoverIn();
      },
      { passive: true }
    );

    // set hovering to true (without showing animation)
    submenu.addEventListener("mouseenter", this.hoverIn);
    submenu.addEventListener("focusin", this.hoverIn);

    // Set a timeout to close menu
    submenu.addEventListener("mouseleave", this.hoverOut);
    submenu.addEventListener("focusout", this.hoverOut);
  }
}
