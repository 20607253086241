// LazyLoadImages
//
// Uses IntersectionObserver to detect when target images scroll into view.
// Then sets image's `src` and `srcset` attributes with `data-src` and
// `data-srcset` values and stops observing.
//
// Based on https://developers.google.com/web/fundamentals/performance/
// lazy-loading-guidance/images-and-video/#inline_images
//
// Requires intersection-observer-polyfill (https://github.com/w3c/
// IntersectionObserver/tree/master/polyfill) to work in older browsers.
//
// Usage:
// <div data-behavior="LazyLoadImages">
//    <img src="optional-placeholder-image.jpg"
//      data-ref="lazyImage"
//      data-src="image-to-lazy-load-1x.jpg"
//      data-srcset="image-to-lazy-load-2x.jpg 2x, image-to-lazy-load-1x.jpg 1x"
//      alt="I'm an image!"
//    >
// </div>

export default class LazyLoadImages {
  constructor(el, props, refs) {
    this.props = props;
    this.refs = refs;

    if (!refs.lazyImage) return false;

    this.images = Array.isArray(refs.lazyImage)
      ? refs.lazyImage
      : [refs.lazyImage];

    this.runObserver();
  }

  runObserver() {
    const options = {
      rootMargin: "0px 0px 100px 0px",
    };

    this.observer = new IntersectionObserver(this.onIntersection, options);

    this.images.forEach((image) => {
      image.classList.add("is-loading");
      this.observer.observe(image);
    });
  }

  onIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const lazyImage = entry.target;
        lazyImage.src = this.dataSrc(lazyImage);
        lazyImage.srcset = this.dataSrcset(lazyImage);
        lazyImage.classList.remove("is-loading");
        lazyImage.classList.add("is-loaded");
        this.observer.unobserve(lazyImage);
      }
    });
  };

  dataSrcset(image) {
    return image.dataset.srcset || this.dataSrc(image);
  }

  dataSrc(image) {
    return image.dataset.src || "";
  }
}
