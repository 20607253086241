import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";

export default class ToggleOverlay extends Sb {
  constructor(el, props, refs) {
    super();
    this.state = {
      open: !!props.open,
    };

    this.refs = refs;
    this.props = props;
    this.props.initClass = refs.overlay.className;

    this.update();
    this.bindEvents();
  }

  update = () => {
    const { overlay } = this.refs;
    const { open } = this.state;

    const overlayClass = classNames(this.props.initClass, {
      "is-closed": !open,
      "is-open": open,
    });

    overlay.className = overlayClass;
  };

  bindEvents() {
    const { overlayOpen, overlayClose } = this.refs;
    const overlayCloseArray = Array.isArray(overlayClose)
      ? overlayClose
      : [overlayClose];

    overlayOpen.addEventListener("click", this.openOverlay);

    [...overlayCloseArray].forEach((closeButton) => {
      closeButton.addEventListener("click", this.closeOverlay);
    });

    // conditionally set focus on firstFocus ref when state is open
    const { overlay, firstFocus } = this.refs;
    const { trapfocus = false } = this.props;
    if (trapfocus && firstFocus) {
      overlay.addEventListener("transitionend", this.setFocus);
    }
  }

  setFocus = (event) => {
    const { overlay, firstFocus } = this.refs;
    const { open } = this.state;

    if (event.target !== overlay) return null;
    firstFocus.focus();
    // Trap focus with CSS
    if (open) {
      overlay.setAttribute("focus-within", true);
    } else {
      overlay.removeAttribute("focus-within");
    }
  };

  closeOnEscape = (event) => {
    if (
      event.key === "Escape" ||
      event.key === "Esc" ||
      event.keyCode === 27 ||
      event.which === 27
    ) {
      this.closeOverlay(event);
    }
  };

  disableScroll = () => {
    document.body.classList.add("has-scroll-lock");
  };

  enableScroll = () => {
    document.body.classList.remove("has-scroll-lock");
  };

  openOverlay = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.addEventListener("keyup", this.closeOnEscape);
    this.setState({ open: true });
    this.disableScroll();
  };

  closeOverlay = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.removeEventListener("keyup", this.closeOnEscape);
    this.setState({ open: false });
    this.enableScroll();
  };
}
