import "whatwg-fetch";
import qs from "query-string";

function handleError(response) {
  console.log("Pages API request failed to complete due to unexpected error"); // eslint-disable-line
}

function handleBadStatus(response) {
  console.log(`API Request completed but status was ${response.status}`); // eslint-disable-line
}

function handleSuccess(response) {
  if (response.status !== 200) {
    handleBadStatus(response);
  }
}

export default function eventsApiRequest(params) {
  const path = "/api/v1/exhibitions";
  const url = params ? path + "?" + qs.stringify(params) : path;
  const fetchPromise = window.fetch(url, {
    headers: { Accept: "application/json" },
  });
  fetchPromise.then(handleSuccess, handleError);
  return fetchPromise;
}
