import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class MembershipTabContent extends PureComponent {
  static displayName = "Content.ContentTabs.MembershipTabContent";

  static propTypes = {
    tab: PropTypes.object,
    addClass: PropTypes.string,
  };

  render() {
    const { tab, addClass } = this.props;
    const hasLink = tab.learn_more_url;

    const baseClass = "m-tabbed-content__level";

    return (
      <div
        className={classNames(
          "m-tabbed-content__level-inner",
          baseClass,
          addClass
        )}
        aria-live="polite"
      >
        <h2 className="m-tabbed-content__heading">{tab.name}</h2>
        <div
          className="m-tabbed-content__description"
          dangerouslySetInnerHTML={{
            __html: tab.description,
          }}
        />
        {hasLink && (
          <div>
            <a
              className="m-button-primary m-button-primary--dark"
              href={tab.learn_more_url}
            >
              Become a Member
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 32"
                className={classNames(
                  "a-icon",
                  "m-button-primary__icon",
                  "m-button-primary__icon--arrow"
                )}
                aria-hidden={true}
              >
                <path
                  d="M22.905 14.665L17.14 8.928l1.878-1.869L28 16l-8.983
                8.941-1.878-1.869 5.767-5.738H4v-2.669z"
                />
              </svg>
            </a>
          </div>
        )}
      </div>
    );
  }
}
