import React from "react";
import PropTypes from "prop-types";

export default function CaretSvg({
  className,
  width = 24,
  height = 24,
  role = "img",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      className={className}
      role={role}
    >
      <polygon points="12.384 14.673 6.297 10.134 6.896 9.333 12.339 13.392 17.049 9.327 17.703 10.084 12.384 14.673" />
    </svg>
  );
}

CaretSvg.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  role: PropTypes.string,
};
