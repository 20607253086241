import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import CSSTransition from "react-transition-group/CSSTransition";

export default class LoaderAnimation extends PureComponent {
  static displayName = "LoaderAnimation";

  static propTypes = {
    loading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <CSSTransition
        in={true}
        timeout={{
          enter: 500,
          exit: 200,
        }}
        classNames="react-appear-"
        appear
      >
        <div className="m-loading-animation is-visible" role="alert">
          <span className="a-hidden">Loading…</span>
          <img
            src="/themes/psam/assets/images/loader.gif"
            alt="Animated depiction of PSAM logo"
            className="m-loading-animation__image"
          />
        </div>
      </CSSTransition>
    );
  }
}
