import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import debounce from "lodash/debounce";

import { connect } from "react-redux";
import Headroom from "headroom.js";

class WaypointNavigation extends PureComponent {
  static displayName = "WaypointNavigation";

  static propTypes = {
    waypoints: PropTypes.arrayOf(PropTypes.object),
    active: PropTypes.number,
  };

  static mapStateToProps = (state, ownProps) => {
    return state.waypoints;
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.handleDropdownBlur = this.handleDropdownBlur.bind(this);
    this.handleDropdownFocus = this.handleDropdownFocus.bind(this);

    this.timeoutId = null;
    this.headroom = null;
  }

  get isUnsupportedBrowser() {
    return (
      !document.documentElement.getAttribute("style") ||
      /Edge\/\d./i.test(navigator.userAgent)
    );
  }

  get activeItemTitle() {
    const { waypoints, active } = this.props;
    return waypoints[active].title;
  }

  componentDidMount() {
    if (!this.navMenu) return;

    this.initMenuPos = this.navMenu.offsetTop;
    this.bindWindowResize();
    this.setHeight();

    this.headroom = new Headroom(this.navMenu, {
      offset: this.initMenuPos,
      onTop: () => {
        this.updateOffset("down");
      },
      onNotTop: () => {
        this.resetTransitionProperty();
        this.updateOffset("up");
      },
    });
    this.headroom.init();
  }

  componentWillUnmount() {
    this.headroom.destroy();
  }

  resetTransitionProperty() {
    // temporarily remove CSS transition so that component
    // doesn't bounce when user scrolls past it
    Object.assign(this.navMenu.style, {
      transition: "none",
    });
    const resetTransition = window.setTimeout(() => {
      Object.assign(this.navMenu.style, {
        transition: "top 0.2s ease",
      });
    }, 200);
  }

  bindWindowResize() {
    const debouncedResize = debounce(() => {
      this.setHeight();
    }, 200);

    window.addEventListener("resize", debouncedResize);
  }

  setHeight() {
    const navMenuBox = this.navMenu.getBoundingClientRect();
    this.navMenuHeight = navMenuBox.height;
    document.documentElement.style.setProperty(
      "--waypoint-nav-height",
      `${this.navMenuHeight}px`
    );
  }

  updateOffset(direction) {
    // due to presence of site header menu when user is scrolling up,
    // we need different offset values for scrolling up or down
    const headerHeight = parseInt(
      window
        .getComputedStyle(document.documentElement)
        .getPropertyValue("--header-height"),
      10
    );
    direction === "down"
      ? (this.headroom.offset = this.initMenuPos)
      : (this.headroom.offset = this.initMenuPos - headerHeight);
  }

  scrollToWaypoint = (event, id, index) => {
    event.stopPropagation();

    // find scroll target in DOM
    const refAttr = "data-anchor-id";
    const targetEl = document.querySelector(`a[${refAttr}="${id}"]`);
    const targetY = targetEl.offsetTop;

    const willScrollUp = window.scrollY > targetY;

    // factor in offset of component height
    let scrollDestination = targetEl.offsetTop + 10;

    // if scrolling up, offset even further due to fixed site header
    if (willScrollUp) {
      const headerHeight = parseInt(
        window
          .getComputedStyle(document.documentElement)
          .getPropertyValue("--header-height"),
        10
      );
      scrollDestination += -headerHeight;
    }

    // smoothly scroll to scrollDestination
    window.scroll({
      top: scrollDestination,
      left: 0,
      behavior: "smooth",
    });
  };

  handleDropdownToggleClick() {
    this.setState({ open: !this.state.open });
  }

  handleWaypointAnchorClick(event, id) {
    this.scrollToWaypoint(event, id);
    this.setState({ open: false });
  }

  handleDropdownFocus() {
    clearTimeout(this.timeoutId);
  }

  handleDropdownBlur() {
    this.timeoutId = setTimeout(() => {
      this.state.open && this.setState({ open: false });
    });
  }

  renderItem(waypoint, index) {
    const { id, title } = waypoint;
    const active = this.props.active;

    const baseClass = "m-navigation-tabbed__link";

    const linkClass = classNames(baseClass, {
      [`${baseClass}--active`]: index === active,
      [`${baseClass}--inactive`]: index !== active,
    });

    return (
      <li key={index} className="m-navigation-tabbed__item">
        <button
          className={linkClass}
          onClick={(event) => this.handleWaypointAnchorClick(event, id)}
        >
          {title}
        </button>
      </li>
    );
  }

  renderListMobile(blockClass) {
    const { open } = this.state;
    const labelId = "page-navigation-listbox-label";
    const buttonId = "page-navigation-listbox-toggle";

    return (
      <div
        className={`${blockClass}__mobile-wrapper`}
        onFocus={this.handleDropdownFocus}
        onBlur={this.handleDropdownBlur}
      >
        <span id="page-navigation-listbox-label" className="a-hidden">
          Select a sibling page to which to navigate
        </span>
        <button
          aria-haspopup="listbox"
          aria-labelledby={`${labelId} ${buttonId}`}
          id={buttonId}
          className={`${blockClass}__toggle ${blockClass}__toggle-border`}
          onClick={(event) => this.handleDropdownToggleClick()}
        >
          {this.activeItemTitle}
        </button>
        <div
          role="listbox"
          aria-labelledby={labelId}
          aria-hidden={!open}
          aria-expanded={open}
          className={classNames(`${blockClass}__dropdown`, {
            "is-closed": !open,
            "is-open": open,
          })}
        >
          <ul className={`${blockClass}__list ${blockClass}__list--mobile`}>
            {this.props.waypoints.map((waypoint, index) => {
              return this.renderItem(waypoint, index);
            })}
          </ul>
        </div>
      </div>
    );
  }

  renderListDesktop(blockClass) {
    return (
      <ul className={`${blockClass}__list ${blockClass}__list--desktop`}>
        {this.props.waypoints.map((waypoint, index) => {
          return this.renderItem(waypoint, index);
        })}
      </ul>
    );
  }

  render() {
    if (!this.props.waypoints || this.isUnsupportedBrowser) return null;

    const blockClass = "m-navigation-tabbed";

    return (
      <nav
        role="navigation"
        className={blockClass}
        ref={(s) => {
          this.navMenu = s;
        }}
      >
        {this.renderListDesktop(blockClass)}
        {this.renderListMobile(blockClass)}
      </nav>
    );
  }
}

export default connect(WaypointNavigation.mapStateToProps)(WaypointNavigation);
