import Percolator from "../../helpers/Percolator";
import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import OpenNestedOverlay from "./OpenNestedOverlay";
import debounce from "lodash/debounce";

export default class ControlNestedOverlays extends Sb {
  constructor(el, props, refs) {
    super();

    const percolator = new Percolator();

    this.state = {
      open: false,
      isMobile: false,
    };

    this.refs = refs;
    this.props = props;
    this.props.initClass = refs.overlay.className;

    const overlayArray = Array.isArray(refs.hasNestedOverlay)
      ? refs.hasNestedOverlay
      : [refs.hasNestedOverlay];

    // Instantiate (and track) the nested overlays
    // with a callback that runs the "close"
    // function on all nested overlays when the parent overlay is closed
    this.overlays = [...overlayArray].map((overlay, index) => {
      return percolator.loadBehavior(overlay, OpenNestedOverlay);
    });

    this.update();
    this.bindResize();
    this.bindToggles();
  }

  update = () => {
    const { overlay, overlayOpen } = this.refs;
    const { open } = this.state;

    const overlayClass = classNames(this.props.initClass, {
      "is-closed": !open,
      "is-open": open,
    });

    overlay.className = overlayClass;
    overlay.setAttribute("aria-hidden", !open);
    overlayOpen.setAttribute("aria-pressed", open);
    this.toggleFocusable();

    !open && this.closeAll();
  };

  closeAll = () => {
    this.overlays.forEach((overlay) => {
      overlay.close();
    });
  };

  bindToggles() {
    const { overlayOpen, overlayClose } = this.refs;

    overlayOpen.addEventListener("click", this.openOverlay);

    overlayClose.addEventListener("click", this.closeOverlay);
  }

  bindResize() {
    // Set overlay to closed state if at wider viewport width
    const debouncedInnerWidth = debounce(() => {
      if (!this.state.open) return null;

      const isMobileWidth = window.innerWidth < 600;

      !isMobileWidth && this.closeOverlay();
    }, 200);

    window.addEventListener("resize", debouncedInnerWidth);
  }

  openOverlay = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.addEventListener("keyup", this.closeOnEscape);
    this.setState({ open: true });
    this.disableScroll();
  };

  closeOverlay = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    window.removeEventListener("keyup", this.closeOnEscape);
    this.setState({ open: false });
    this.enableScroll();
  };

  closeOnEscape = (event) => {
    if (
      event.key === "Escape" ||
      event.key === "Esc" ||
      event.keyCode === 27 ||
      event.which === 27
    ) {
      this.closeOverlay(event);
    }
  };

  disableScroll = () => {
    document.body.classList.add("has-scroll-lock");
  };

  enableScroll = () => {
    document.body.classList.remove("has-scroll-lock");
  };

  toggleFocusable = () => {
    const { overlay } = this.refs;
    const { open } = this.state;
    const focusable = overlay.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    // If closed, remove focusable elements
    focusable.forEach((item, i) => {
      item.setAttribute("tabindex", open ? 0 : -1);
    });
  };
}
