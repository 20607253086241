import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class ExhibitionListOptionList extends PureComponent {
  static displayName = "ExhibitionList.Filters.OptionList";

  static propTypes = {
    type: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    selectedOptions: PropTypes.array,
    hideAllOption: PropTypes.bool,
    handleOptionClick: PropTypes.func.isRequired,
    handleListClose: PropTypes.func.isRequired,
    labelledBy: PropTypes.string,
    blockClass: PropTypes.string,
    tabbable: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      height: null,
    };

    this.optionList = React.createRef();
    this.firstFocussable = React.createRef();

    this.handleFocus = this.handleFocus.bind(this);

    this.timeoutId = null;
    this.resizing = false;
  }

  componentDidMount() {
    // toggle scroll-lock CSS to document body as side effect
    document.body.classList.add("has-scroll-lock");
    // set height of component in CSS to handle overflow scroll
    this.setRefHeight();
    // update ref height on resize
    window.addEventListener("resize", this.handleResize);
    // add keyup event listener for list interaction
    document.addEventListener("keyup", this.handleKeyUp, false);
    // focus on first option in list using ref
    this.focusable[0].focus();
  }

  componentWillUnmount() {
    // remove event listeners & scroll-lock on document body
    document.body.classList.remove("has-scroll-lock");
    window.removeEventListener("resize", this.handleResize);
    document.removeEventListener("keyup", this.handleKeyUp, false);
  }

  get hasAnySelections() {
    return this.props.selectedOptions.length > 0;
  }

  get focusable() {
    return Array.from(this.optionList.current.querySelectorAll("button"));
  }

  handleFocus() {
    clearTimeout(this.timeoutId);
  }

  handleArrowKeyUp() {
    const focusedItem = document.activeElement;
    const focusableIndex = this.focusable.indexOf(focusedItem);

    focusableIndex === 0
      ? this.focusable[this.focusable.length - 1].focus()
      : this.focusable[focusableIndex - 1].focus();
  }

  handleArrowKeyDown() {
    const focusedItem = document.activeElement;
    const focusableIndex = this.focusable.indexOf(focusedItem);

    focusableIndex === this.focusable.length - 1
      ? this.focusable[0].focus()
      : this.focusable[focusableIndex + 1].focus();
  }

  handleTabKey(shift) {
    const focusedItem = document.activeElement;
    const focusableIndex = this.props.tabbable.indexOf(focusedItem);

    if (focusableIndex < 0) {
      if (shift) {
        this.props.tabbable[this.props.tabbable.length - 1].focus();
      } else {
        this.props.tabbable[0].focus();
      }
    }
  }

  handleKeyUp = (event) => {
    event.preventDefault();

    switch (event.key) {
      case "Esc": // IE/Edge specific value
      case "Escape":
        this.props.handleListClose();
        break;
      case "ArrowUp":
        this.handleArrowKeyUp();
        break;
      case "ArrowDown":
        this.handleArrowKeyDown();
        break;
      case "Tab":
        this.handleTabKey(event.shiftKey);
        break;
    }
  };

  handleResize = () => {
    if (!this.resizing) {
      window.requestAnimationFrame(() => {
        this.setRefHeight();
        this.resizing = false;
      });

      this.resizing = true;
    }
  };

  setRefHeight() {
    const optionList = this.optionList.current;
    const box = optionList.getBoundingClientRect();

    this.setState({ height: window.innerHeight - box.top });
  }

  capitalize(string) {
    if (!string || typeof string !== "string") return "";

    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderCloseButton() {
    const elemClass = `${this.props.blockClass}__close-button`;

    return (
      <button
        className={`${elemClass} ${elemClass}--mobile`}
        onClick={this.props.handleListClose}
      >
        <span className="a-hidden">Close filter list</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          aria-hidden={true}
          className={`${this.props.blockClass}__close-icon`}
        >
          {/* eslint-disable max-len */}
          <path
            fillRule="evenodd"
            d="M30.675 28.651L18.024 16 30.675 3.349l-2.024-2.024L16 13.976 3.349 1.325 1.325 3.349 13.976 16 1.325 28.651l2.024 2.024L16 18.024l12.651 12.651 2.024-2.024z"
          />
          {/* eslint-enable max-len */}
        </svg>
      </button>
    );
  }

  renderOption(type, option, isAllOption) {
    const optionIsSelected =
      this.props.selectedOptions &&
      this.props.selectedOptions.find((item) => item.id === option.id);
    const allOptionIsSelected = isAllOption && !this.hasAnySelections;
    const blockClass = this.props.blockClass;
    const buttonClass = `${blockClass}__button`;

    return (
      <li
        key={option.id}
        role="option"
        className={`${blockClass}__option`}
        aria-selected={!!optionIsSelected || allOptionIsSelected}
      >
        <button
          className={classNames(buttonClass, {
            [`${buttonClass}--left`]: type === "Timeframe",
            [`${buttonClass}--right`]: type === "Locations",
            [`${buttonClass}--is-selected`]:
              !!optionIsSelected || allOptionIsSelected,
          })}
          onClick={(event) => {
            this.props.handleOptionClick(type, option, isAllOption);
          }}
        >
          {type === "Timeframe" ? this.capitalize(option.name) : option.name}
        </button>
      </li>
    );
  }

  render() {
    const { type, options, hideAllOption, labelledBy, blockClass } = this.props;
    const allOption = {
      id: `${type}-all`,
      name: `All ${type === "Timeframe" ? "Events" : type}`,
    };
    const optionListClass = `${blockClass}__option-listbox`;

    return (
      <div
        ref={this.optionList}
        role="listbox"
        aria-labelledby={labelledBy}
        aria-hidden={false}
        aria-expanded={true}
        className={classNames(optionListClass, {
          [`${optionListClass}--left`]: type === "Timeframe",
          [`${optionListClass}--right`]: type === "Locations",
        })}
        style={{ maxHeight: this.state.height ? this.state.height : "auto" }}
        onFocus={this.handleFocus}
      >
        <ul className={`${blockClass}__option-list`}>
          {!hideAllOption && this.renderOption(type, allOption, true)}
          {options.map((option) => this.renderOption(type, option, false))}
        </ul>
        {this.renderCloseButton()}
      </div>
    );
  }
}
