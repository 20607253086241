import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Slideshow from "./Slideshow";
import { connect } from "react-redux";
import orderBy from "lodash/orderBy";
import CSSTransition from "react-transition-group/CSSTransition";
import ScrollLock from "cic-scroll-lock";

class ImageGridSlideshow extends PureComponent {
  static displayName = "ImageGridSlideshow";

  static propTypes = {
    current: PropTypes.string,
    imageOffsets: PropTypes.array,
    open: PropTypes.bool,
    slides: PropTypes.array,
    dispatch: PropTypes.func,
  };

  static mapStateToProps = (state, ownProps) => {
    return state.slideshow;
  };

  constructor(props) {
    super();
    this.slideshowRef = React.createRef();
    this.scrollLock = new ScrollLock();

    this.state = {
      active: 0,
    };
  }

  componentDidMount() {
    const sortedProps = Object.assign({}, this.props);

    // sort images in global state by their Y-position on page
    const sortedImages = orderBy(sortedProps.imageOffsets, "YOffset");

    // now reorder slides to match this order
    const sortedSlides = sortedProps.slides
      .map((image) => {
        const n = sortedImages.map((e) => e.id).indexOf(image.id);
        return [n, image];
      })
      .sort()
      .map((j) => j[1]);

    sortedProps.slides = sortedSlides;

    this.sortedProps = sortedProps;
  }

  componentDidUpdate(prevProps) {
    // If slideshow is being opened, set active state to
    // index of clicked image in sortedProps.slides array
    const slideshowEl = this.slideshowRef?.current;

    const { slides } = this.sortedProps;
    const current = this.props.current ? this.props.current : 0;
    const index = slides.findIndex((slide) => slide.id === this.props.current);
    const active = Math.max(0, index);

    if (this.props.open && this.props.open !== prevProps.open) {
      this.setState({ active });
    }

    if (this.props.open && slideshowEl) {
      this.scrollLock.only(slideshowEl);
      document.body.classList.add("has-scroll-lock");
    } else if (prevProps.open && !this.props.open && slideshowEl) {
      this.scrollLock.any(slideshowEl);
      document.body.classList.remove("has-scroll-lock");

      // return focus to DOM element that initially opened slideshow
      const current = document.querySelector(
        `[data-props-id="${this.props.current}"]`
      );

      if (current) {
        current.focus();
      }
    }
  }

  closeSlideshow = (event) => {
    // eslint-ignore-next-line react/prop-types
    this.props.dispatch({
      type: "CLOSE_SLIDESHOW",
    });
  };

  setActive = (active) => {
    this.setState({ active });
  };

  render() {
    const { open } = this.props;
    return (
      <CSSTransition
        in={open}
        timeout={300}
        classNames="react-fade-"
        unmountOnExit
      >
        {(open) => (
          <Slideshow
            slides={this.sortedProps.slides}
            active={this.state.active}
            onUpdate={this.setActive}
            onClose={this.closeSlideshow}
            slideshowRef={this.slideshowRef}
          />
        )}
      </CSSTransition>
    );
  }
}

export default connect(ImageGridSlideshow.mapStateToProps)(ImageGridSlideshow);
