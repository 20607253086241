import React from "react";
import reactDOM from "react-dom";
import hasIn from "lodash/hasIn";
import dasherize from "underscore.string/dasherize";
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducers from "./reducers";

export default class Percolator {
  constructor(options = {}) {
    const defaultOptions = {
      componentAttr: "[data-component]",
      behaviorAttr: "[data-behavior]",
      refAttr: "[data-ref]",
      loadedKey: "loaded", // [data-loaded]
      propsKey: "props", // [data-props]
    };
    this.store = createStore(reducers);
    this.options = Object.assign({}, defaultOptions, options);
  }

  setOptions(options) {
    this.options = Object.assign({}, this.options, options);
  }

  attrToKey(attr) {
    return attr.match(/\[data-([-\w]+)/)[1];
  }

  getRefsObject(els) {
    const obj = {};
    // Get any named refs inside the parent
    [...els].forEach((el) => {
      const attr = el.dataset[this.attrToKey(this.options.refAttr)];
      // If ref attributes are named, pass them
      // as an object to the parent
      if (attr.length > 0) {
        // Often, there is only one element per ref
        // So save it as a singular element
        if (!obj[attr]) {
          obj[attr] = el;
        } else {
          // Unless there
          // are more than one, then use an array
          obj[attr] = Array.isArray(obj[attr])
            ? obj[attr].concat([el])
            : [obj[attr], el];
        }
      }
    });

    return obj;
  }

  checkLoaded(dataset, name) {
    const loadedKey = this.options.loadedKey;
    if (!hasIn(dataset, loadedKey)) return false;
    const loaded = dataset[loadedKey];
    if (loaded.indexOf(name) > -1) return false;
    return true;
  }

  validateMatch(el, attr, group, loader) {
    const { loadedKey, propsAttr } = this.options;
    const name = el.dataset[this.attrToKey(attr)];

    // For each match, get the value of its directive name
    // and check if it exists in the loader group
    if (hasIn(group, name)) {
      // If so, use the private loadBehavior function
      // Only if the match doesn't have the loaded attribute
      if (!this.checkLoaded(el.dataset, name)) {
        // Record the name of the script loaded on the el in html
        el.dataset[loadedKey] = el.dataset[loadedKey]
          ? el.dataset[loadedKey] + ` ${name}`
          : name;
        loader(el, group[name]);
      }
    }
  }

  loadGroup(group, parent, attr, loader) {
    // Load JS behaviors (classes that act on existing markup)
    const matches = parent.querySelectorAll(attr);

    [...matches].forEach((match) => {
      // Validate each match and pass it to private loader
      this.validateMatch(match, attr, group, loader);
    });
  }

  // Frontend APIs
  // --------------------
  // Can be used to load a single Behavior from JS or
  // used internally
  loadBehavior = (el, Behavior, jsProps = {}, jsRefs = {}) => {
    const { refAttr, propsKey } = this.options;
    const htmlRefs = el.querySelectorAll(refAttr);

    // Refs and props can be provided via JS or HTML
    // and they get combined here
    const refs = Object.assign({}, jsRefs, this.getRefsObject(htmlRefs));

    jsProps.dispatch = this.store.dispatch;

    const props = Object.assign(
      {},
      jsProps,
      hasIn(el.dataset, propsKey) ? JSON.parse(el.dataset[propsKey]) : {},
      this.getPropsStartingWith(el.dataset, propsKey)
    );

    // Behaviors get loaded with props and refs
    const loaded = new Behavior(el, props, refs);

    return loaded;
  };

  // Can be used to load a single component from JS or
  // used internally
  loadComponent = (el, Component, jsProps = {}) => {
    const { propsKey } = this.options;

    // Props can be provided via JS or HTML
    // and they get combined here
    const props = Object.assign(
      {},
      jsProps,
      hasIn(el.dataset, propsKey) ? JSON.parse(el.dataset[propsKey]) : {},
      this.getPropsStartingWith(el.dataset, propsKey)
    );

    // Behaviors get loaded with props and refs
    const toRender = (
      <Provider store={this.store}>
        <Component {...props} />
      </Provider>
    );
    reactDOM.render(toRender, el);
  };

  loadBehaviors(group, parent = document) {
    // Take a group of JS classes and instantiate them on any dom elements
    // that match the class name and are not loaded
    const attr = this.options.behaviorAttr;

    this.loadGroup(group, parent, attr, this.loadBehavior);
  }

  loadComponents(group, parent = document) {
    // Take a group of JS classes and instantiate them on any dom elements
    // that match the class name and are not loaded
    const attr = this.options.componentAttr;

    this.loadGroup(group, parent, attr, this.loadComponent);
  }

  getPropsStartingWith(dataset, prepend) {
    const obj = {};
    for (const key in dataset) {
      if (key.indexOf(prepend) === 0) {
        const dasherizedKey = dasherize(key).substring(prepend.length + 1);
        if (dasherizedKey.length > 0) obj[dasherizedKey] = dataset[key];
      }
    }
    return obj;
  }
}
