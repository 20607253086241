import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CSSTransition from "react-transition-group/CSSTransition";
import DisplayName from "../DisplayName";

export default class EntityListHero extends PureComponent {
  static displayName = "EntityList.Hero";

  static propTypes = {
    entity: PropTypes.object.isRequired,
    path: PropTypes.string,
    type: PropTypes.string,
    venue: PropTypes.string,
    category: PropTypes.string,
    className: PropTypes.string,
  };

  get url() {
    const path = this.props.path;
    const slug = this.props.entity.slug;

    if (path) return `${path}/${slug}`;

    return slug;
  }

  get showTwoColLayout() {
    const { list_layout: listLayout } = this.props.entity;

    return listLayout === "2_col";
  }

  renderDetailsText(blockClass, small) {
    return (
      <React.Fragment>
        <span
          className={classNames(`${blockClass}__details-text`, {
            [`${blockClass}__details-text--small`]: small,
          })}
        >
          Details
        </span>
        <svg
          viewBox="0 0 100 2"
          width={100}
          height={2}
          xmlns="http://www.w3.org/2000/svg"
          className={`${blockClass}__details-svg`}
        >
          <line x1="0" y1="1" x2="100" y2="1" stroke="currentColor" />
        </svg>
      </React.Fragment>
    );
  }

  renderHeading(blockClass) {
    const date = this.props.entity.date_text
      ? this.props.entity.date_text
      : this.props.entity.dateRange;
    return (
      <React.Fragment>
        <h2 className={`${blockClass}__title`}>
          <DisplayName
            name={this.props.entity.name}
            displayName={this.props.entity.display_name}
          />
        </h2>
        <div className={`${blockClass}__description`}>
          <div>{this.props.venue}</div>
          <time>{date}</time>
        </div>
        <div className={`${blockClass}__footer`} aria-hidden={true}>
          {this.renderDetailsText(blockClass, true)}
        </div>
      </React.Fragment>
    );
  }

  renderImage(blockClass, useLargeImage) {
    const { name, imageMedium, imageLarge } = this.props.entity;
    const imageSrc = useLargeImage ? imageLarge : imageMedium;

    return (
      <React.Fragment>
        <img className={`${blockClass}__image`} src={imageSrc} alt="" />
        <div className={`${blockClass}__hover-box`} aria-hidden={true}>
          {this.renderDetailsText(blockClass)}
        </div>
      </React.Fragment>
    );
  }

  renderTwoColumn(blockClass, layoutClass) {
    return (
      <article className={`${layoutClass} ${layoutClass}--unpadded`}>
        <header
          className={classNames(
            `${blockClass}__heading`,
            `${blockClass}__heading--column`,
            `${layoutClass}__child`,
            `${layoutClass}__child--5`,
            "l-container-secondary-nested"
          )}
        >
          {this.renderHeading(blockClass)}
        </header>
        <figure
          className={classNames(
            `${blockClass}__figure`,
            `${layoutClass}__child`,
            `${layoutClass}__child--7`
          )}
        >
          {this.renderImage(blockClass, false)}
        </figure>
      </article>
    );
  }

  renderOneColumn(blockClass, layoutClass) {
    return (
      <article className={`${layoutClass} ${layoutClass}--unpadded`}>
        <header
          className={classNames(
            `${blockClass}__heading`,
            `${blockClass}__heading--row`,
            `${layoutClass}__child`,
            `${layoutClass}__child--12`
          )}
        >
          {this.renderHeading(blockClass)}
        </header>
        <figure
          className={classNames(
            `${blockClass}__figure`,
            `${layoutClass}__child`,
            `${layoutClass}__child--12`
          )}
        >
          {this.renderImage(blockClass, true)}
        </figure>
      </article>
    );
  }

  render() {
    const { slug, list_background: listBackground } = this.props.entity;
    const blockClass = "m-entity-block";
    const layoutClass = "m-12-column-grid";
    const bgColorClass = `a-bg-${listBackground || "white"}`;

    return (
      <CSSTransition in={true} timeout={200} classNames="react-appear-" appear>
        <li
          className={classNames(blockClass, bgColorClass, this.props.className)}
        >
          <a href={this.url} className={`${blockClass}__link`}>
            {this.showTwoColLayout
              ? this.renderTwoColumn(blockClass, layoutClass)
              : this.renderOneColumn(blockClass, layoutClass)}
          </a>
        </li>
      </CSSTransition>
    );
  }
}
