import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class SlideshowCalloutCaption extends PureComponent {
  static displayName = "SlideshowCallout.Caption";

  static propTypes = {
    base: PropTypes.string,
    slides: PropTypes.array.isRequired,
    active: PropTypes.number,
  };

  static defaultProps = {
    base: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      body: this.props.base,
    };
  }

  componentDidUpdate(prevProps) {
    const { base, slides, active } = this.props;
    const caption = slides[active].text;
    // string output from CMS if field left blank
    const empty = "\r\n";

    if (active !== prevProps.active) {
      // check if current or prev slide has caption
      if (caption !== empty || this.state.body !== base) {
        // if current has caption, set state.body to that
        // or if prev had caption, set state.body back to base text
        const newBody = caption !== empty ? caption : base;
        this.setState({ body: newBody });
      }
    }
  }

  render() {
    return (
      <div
        className={classNames("m-slideshow-callout__caption", "m-content-rte")}
        dangerouslySetInnerHTML={{
          __html: this.state.body,
        }}
        aria-live="polite"
      />
    );
  }
}
