import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import eventsApiRequest from "../../../api/Events";
import LoaderAnimation from "../../../components/site/LoaderAnimation";
import Filters from "./Filters";
import Thumbnail from "../EntityList/Thumbnail";
import Placeholder from "../EntityList/Placeholder";

export default class EventList extends PureComponent {
  static displayName = "EventList.Wrapper";

  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    locations: PropTypes.arrayOf(PropTypes.object),
    educationonly: PropTypes.string,
    pastonly: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: true,
      errored: false,
      loadingFromFilters: false,
      activePage: 1,
      events: [],
      pagination: {},
      currentCategories: [],
      currentLocations: [],
    };
    this.scrolling = false;
    this.timeoutId = null;
    this.eventList = React.createRef();
  }

  componentDidMount() {
    this.setItemsFromFilters();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.filtersHaveUpdated(prevState)) {
      this.setItemsFromFilters();
    } else if (prevState.activePage !== this.state.activePage) {
      // run if state.activePage changes (but only if currentCategories or
      // currentLocations aren't also updated...this prevents these two methods
      // from firing consecutively
      this.setItemsFromPagination();
    }

    // add/remove scroll event listener based on state.pagination
    this.maybeUpdateScrollListener(prevState);
  }

  get showPlaceholder() {
    const { loaded, events } = this.state;
    return loaded && events.length === 0;
  }

  get categoryLabel() {
    const { educationonly, pastonly } = this.props;

    if (educationonly) return "Educational Events";
    if (pastonly) return "Past Events";
    return "Events";
  }

  filtersHaveUpdated(prevState) {
    return (
      !isEqual(prevState.currentCategories, this.state.currentCategories) ||
      !isEqual(prevState.currentLocations, this.state.currentLocations)
    );
  }

  maybeUpdateScrollListener(prevState) {
    if (prevState.pagination.totalPages === this.state.pagination.totalPages)
      return false;

    if (this.state.pagination.totalPages > 1) {
      // add listener when totalPages increases beyond 1
      window.addEventListener("scroll", this.handleScroll);
    } else if (
      prevState.pagination.totalPages > 1 &&
      this.state.pagination.totalPages <= 1
    ) {
      // remove event listener if totalPages was greater than 1 and now isn't
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  updateSelectedFilters = (newCategories, newLocations) => {
    this.setState({
      currentCategories: newCategories,
      currentLocations: newLocations,
    });
  };

  resetFilters = () => {
    this.setState({
      currentCategories: [],
      currentLocations: [],
    });
  };

  // construct and fire API request based on changes from filters
  // filters are multiselect, so queries are constructed by combining selections
  setItemsFromFilters() {
    const { currentCategories, currentLocations, pagination } = this.state;
    const params = {
      "categories[]": this.reduceToArray(currentCategories, "id"),
      "where[]": this.reduceToArray(currentLocations, "id"),
      page: 1, // reset page to 1
      educational: this.props.educationonly ? 1 : 0,
      past: this.props.pastonly ? 1 : 0,
    };

    this.setState(
      {
        loaded: false,
        loadingFromFilters: true,
      },
      () => {
        eventsApiRequest(params)
          .catch((error) => {
            console.log(error); // eslint-disable-line
            this.setState({
              loaded: true,
              errored: true,
            });
          })
          .then((results) => {
            return results.json();
          })
          .then((data) => {
            this.setState({
              loaded: true,
              errored: false,
              loadingFromFilters: false,
              activePage: 1,
              events: data.events,
              pagination: data.pagination,
            });
          });
      }
    );
  }

  // construct and fire API request based on pagination change
  // similar to `setItemsFromFilters`, but because new page items are appended
  // to old items, we set state.events by merging old and new states
  setItemsFromPagination() {
    const { currentCategories, currentLocations, activePage } = this.state;
    const params = {
      "categories[]": this.reduceToArray(currentCategories, "id"),
      "where[]": this.reduceToArray(currentLocations, "id"),
      page: activePage,
      educational: this.props.educationonly ? 1 : 0,
      past: this.props.pastonly ? 1 : 0,
    };

    this.setState({ loaded: false }, () => {
      eventsApiRequest(params)
        .then((results) => {
          return results.json();
        })
        .then((data) => {
          this.setState({
            loaded: true,
            events: [...this.state.events, ...data.events],
            pagination: data.pagination,
          });
        });
    });
  }

  handleScroll = () => {
    // listen for scroll and trigger pagination when end of eventList is reached
    if (!this.scrolling) {
      window.requestAnimationFrame(() => {
        const eventList = this.eventList.current;
        const box = eventList.getBoundingClientRect();

        if (window.innerHeight >= box.bottom) {
          this.timeoutId = setTimeout(() => {
            this.paginateOnScroll();
          }, 300);
        }

        this.scrolling = false;
      });

      this.scrolling = true;
      clearTimeout(this.timeoutId);
    }
  };

  paginateOnScroll = () => {
    const { currentPage, totalPages } = this.state.pagination;

    // bail if next fetch from API hasn't loaded, or if at end of pages
    if (!this.state.loaded || currentPage === totalPages) return false;

    this.setState({
      activePage: this.state.activePage + 1,
    });
  };

  scrollToTop() {
    this.eventList.current.scrollIntoView({ behavior: "smooth" });
  }

  // helper method to return an array of values from an array of objects
  reduceToArray(objArray, key) {
    return objArray.reduce((accumulator, currentValue) => {
      return [...accumulator, currentValue[key]];
    }, []);
  }

  getPropNameById(prop, id) {
    const propObj = find(this.props[prop], ["id", id]);
    return propObj ? propObj.name : null;
  }

  render() {
    const {
      loaded,
      errored,
      loadingFromFilters,
      events,
      currentCategories,
      currentLocations,
      pagination,
    } = this.state;
    const { categories, locations } = this.props;
    const listClass = "m-thumbnail-list";

    return (
      <React.Fragment>
        <Filters
          categories={categories}
          locations={locations}
          categoryLabel={this.categoryLabel}
          handleOptionListClose={this.updateSelectedFilters}
          handleResetClick={this.resetFilters}
        />
        {this.showPlaceholder && <Placeholder type="event" />}
        {errored && <Placeholder type="error" />}
        <ul
          ref={this.eventList}
          className={classNames(listClass, {
            [`${listClass}--loading`]: loadingFromFilters,
          })}
          aria-live="polite"
          aria-busy={!loaded}
        >
          {!loaded && <LoaderAnimation />}
          {events.map((event, index) => {
            return (
              <Thumbnail
                key={`${event.id}-${index}`}
                entity={event}
                type={"event"}
                showTime={true}
                showYear={false}
                venue={this.getPropNameById("locations", event.venue)}
                category={this.getPropNameById(
                  "categories",
                  event.primary_event_category_id
                )}
                className={`${listClass}__item`}
              />
            );
          })}
        </ul>
        {pagination.currentPage < pagination.totalPages && (
          <footer className="m-entity-list-footer">
            <span className="m-entity-list-footer__text">Scroll For More</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width={20}
              height={20}
              className="m-entity-list-footer__icon"
            >
              {/* eslint-disable-next-line max-len */}
              <path d="M17.335 22.905l5.737-5.765 1.869 1.878L16 28l-8.941-8.983 1.869-1.878 5.738 5.767V4h2.669z" />
            </svg>
          </footer>
        )}
      </React.Fragment>
    );
  }
}
