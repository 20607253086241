import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import debounce from "lodash/debounce";

export default class ExpandAccordion extends Sb {
  constructor(el, props, refs) {
    super();

    this.state = {
      open: false,
      height: refs.accordionToggle.offsetHeight,
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.props.initElClass = el.className;

    // Update once on load
    this.update();
    this.bindToggle();
    this.bindResize();

    if (this.refs.accordionAnchor) {
      this.openAnchor();
    }

    this.animationTimer = null;
  }

  update = (e) => {
    const { open, height } = this.state;

    this.el.className = classNames(this.props.initElClass, {
      "is-open": open,
    });

    this.refs.accordionToggle.setAttribute(
      "aria-expanded",
      open ? "true" : "false"
    );

    Object.assign(this.el.style, { maxHeight: height + "px" });
  };

  bindToggle() {
    this.refs.accordionToggle.addEventListener("click", this.handleToggle);
  }

  openAnchor() {
    if (window.location.hash === "#" + this.refs.accordionAnchor.name) {
      this.handleToggle();
    }
  }

  bindResize() {
    const { accordionToggle, fullContent } = this.refs;

    const debouncedHeight = debounce(() => {
      if (this.state.open) {
        this.setState({
          height: accordionToggle.offsetHeight + fullContent.offsetHeight,
        });
      } else {
        this.setState({
          height: accordionToggle.offsetHeight,
        });
      }
    }, 200);

    window.addEventListener("resize", debouncedHeight);
  }

  handleToggle = () => {
    const { open } = this.state;
    const { accordionToggle, fullContent } = this.refs;

    this.setState({
      open: !open,
      height:
        accordionToggle.offsetHeight + (!open && fullContent.offsetHeight),
    });
  };
}
