import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import CSSTransition from "react-transition-group/CSSTransition";

export default class ExhibitionListYearFilter extends PureComponent {
  static displayName = "ExhibitionList.YearFilter";

  static propTypes = {
    years: PropTypes.arrayOf(PropTypes.number).isRequired,
    currentYear: PropTypes.number,
    handleOptionSelect: PropTypes.func.isRequired,
  };

  render() {
    const { years, currentYear } = this.props;
    return (
      <CSSTransition in={true} timeout={200} classNames="react-appear-" appear>
        <form className="m-form m-form--heading l-pad-bottom-medium-large">
          <label htmlFor="exhibition-year-select" className="a-hidden">
            Select a year:
          </label>
          <select
            id="exhibition-year-select"
            name="year"
            className="m-form__select"
            value={currentYear}
            onChange={(event) => {
              const value = parseInt(event.target.value, 10);
              this.props.handleOptionSelect(value);
            }}
          >
            {years.map((year) => {
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </select>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            className="a-icon m-form__caret-icon"
            aria-hidden="true"
          >
            {/* eslint-disable-next-line max-len */}
            <polygon points="51.799 25.227 31.617 45.522 11.435 25.227 51.799 25.227" />
          </svg>
        </form>
      </CSSTransition>
    );
  }
}
