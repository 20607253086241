import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CSSTransition from "react-transition-group/CSSTransition";
import xor from "lodash/xor";
import OptionList from "./OptionList";
import CaretSvg from "../../svg/CaretSvg";

export default class EventListFilters extends PureComponent {
  static displayName = "EventList.Filters";

  static propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    locations: PropTypes.arrayOf(PropTypes.object),
    hideLocations: PropTypes.bool,
    categoryLabel: PropTypes.string,
    handleOptionListClose: PropTypes.func.isRequired,
    handleResetClick: PropTypes.func.isRequired,
    blockClass: PropTypes.string,
  };

  static defaultProps = {
    categoryLabel: "",
    blockClass: "m-entity-list-filters",
  };

  constructor(props) {
    super(props);

    this.state = {
      // store OptionList selections in state, separate from inherited props;
      // this allows us to pass filter updates to parent all at once,
      // when filter option list closes
      selectedCategories: [],
      selectedLocations: [],
      open: null,
    };

    this.timeout = 300;

    this.setOptionListToggleRef = (type, element) => {
      this[`optionListToggle${type}`] = element;
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // when a option list closes:
    // 1) send selections stored in state to parent component via prop
    // 2) return focus to that list's toggle
    // (but delay it to match transition time for OptionList component)
    if (prevState.open && !this.state.open) {
      this.props.handleOptionListClose(
        this.state.selectedCategories,
        this.state.selectedLocations
      );
      const focusTimeout = window.setTimeout(() => {
        prevState.open === "Categories"
          ? this.optionListToggleCategories.focus()
          : this.optionListToggleLocations.focus();
      }, this.timeout);
    }

    document.body.dataset.filtersOpen = !!this.state.open;
  }

  get showLocations() {
    return this.props.locations && this.props.locations.length > 0;
  }

  get allSelections() {
    const { selectedLocations, selectedCategories } = this.state;
    return selectedCategories.concat(selectedLocations);
  }

  get hasAnySelections() {
    return this.allSelections.length > 0;
  }

  get activeCategory() {
    const selectedCategories = this.state.selectedCategories;
    const count = selectedCategories.length;
    const categoryLabel = this.props.categoryLabel;

    if (count > 1) return `Some ${categoryLabel}`;

    if (count === 0) return `All ${categoryLabel}`;

    return selectedCategories[0].plural_name;
  }

  get activeLocation() {
    const selectedLocations = this.state.selectedLocations;
    const count = selectedLocations.length;

    if (count > 1) return "Some Locations";

    if (count === 0) return "All Locations";

    return "One Location";
  }

  get tabbable() {
    return Array.from(
      this.filterBlock.querySelectorAll('button:not([tabindex="-1"])')
    );
  }

  closeOptionList = () => {
    if (!this.state.open) return null;
    this.setState({ open: null });
  };

  handleOptionClick = (type, option, isAllOption) => {
    if (type === "Categories") {
      const newOptions = isAllOption
        ? []
        : xor(this.state.selectedCategories, [option]);
      this.setState({ selectedCategories: newOptions });
    } else if (type === "Locations") {
      const newOptions = isAllOption
        ? []
        : xor(this.state.selectedLocations, [option]);
      this.setState({ selectedLocations: newOptions });
    }
  };

  handleResetAllClick = () => {
    this.setState({
      selectedCategories: [],
      selectedLocations: [],
      open: null,
    });
    this.props.handleResetClick();
  };

  handleListToggleClick = (type, event) => {
    const typeIsOpen = type === this.state.open;

    this.setState({ open: !typeIsOpen ? type : null });
  };

  renderCloseButton() {
    const elemClass = `${this.props.blockClass}__close-button`;

    return (
      <button
        className={classNames(
          elemClass,
          `${elemClass}--desktop`,
          `${elemClass}--is-active`
        )}
        onClick={this.closeOptionList}
      >
        <span className={`${elemClass}__close-text`}>Close</span>
      </button>
    );
  }

  renderResetAllButton() {
    const elemClass = `${this.props.blockClass}__reset-button`;

    return (
      <button
        className={classNames(elemClass, `${elemClass}--is-active`, {
          [`${elemClass}--filters-closed`]: !this.state.open,
        })}
        onClick={this.handleResetAllClick}
      >
        Reset
      </button>
    );
  }

  renderOptions(type, options) {
    const isOpen = this.state.open === type;
    const selectedOptions =
      type === "Categories"
        ? this.state.selectedCategories
        : this.state.selectedLocations;
    const labelId = `filter-${type}-label`;
    const buttonId = `filter-${type}-button`;
    const blockClass = this.props.blockClass;

    return (
      <span className={`${blockClass}__filter`}>
        <span id={labelId} className="a-hidden">
          {`Select ${type}`}
        </span>
        <button
          ref={this.setOptionListToggleRef.bind(this, type)}
          aria-haspopup="listbox"
          aria-labelledby={`${labelId} ${buttonId}`}
          id={buttonId}
          className={`${blockClass}__current`}
          onClick={(event) => {
            this.handleListToggleClick(type, event);
          }}
          tabIndex={this.state.open ? -1 : 0}
        >
          <span className={`${blockClass}__current-text`}>
            {type === "Categories" ? this.activeCategory : this.activeLocation}
            <CaretSvg className="a-icon" role="presentation" />
          </span>
        </button>
        <CSSTransition
          in={isOpen}
          timeout={this.timeout}
          classNames="react-fade-"
          unmountOnExit
        >
          {(isOpen) => (
            <OptionList
              type={type}
              options={options}
              selectedOptions={selectedOptions}
              handleOptionClick={this.handleOptionClick}
              handleListClose={this.closeOptionList}
              labelledBy={labelId}
              blockClass={blockClass}
              tabbable={this.tabbable}
            />
          )}
        </CSSTransition>
      </span>
    );
  }

  renderLocations(blockClass) {
    const locations = this.props.locations.filter((loc) => !loc.hide_in_filter);

    return (
      <React.Fragment>
        <span
          className={classNames(`${blockClass}__small-text`, {
            [`${blockClass}__small-text--is-active`]:
              this.state.open === "Locations",
          })}
        >
          {" at "}
        </span>
        {this.renderOptions("Locations", locations)}
      </React.Fragment>
    );
  }

  render() {
    const blockClass = this.props.blockClass;

    return (
      <div
        className={`${blockClass} l-container-wide`}
        ref={(filterBlock) => (this.filterBlock = filterBlock)}
      >
        <div className={`${blockClass}__inner`}>
          <span
            className={classNames(`${blockClass}__small-text`, {
              [`${blockClass}__small-text--is-active`]:
                this.state.open === "Categories",
            })}
          >
            {"Show me "}
          </span>
          {this.renderOptions("Categories", this.props.categories)}
          {this.showLocations && this.renderLocations(blockClass)}
        </div>
        {this.state.open && this.renderCloseButton()}
        {this.hasAnySelections && this.renderResetAllButton()}
      </div>
    );
  }
}
