import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import CSSTransition from "react-transition-group/CSSTransition";

export default class EntityListPlaceholder extends PureComponent {
  static displayName = "EntityList.Placeholder";

  static propTypes = {
    type: PropTypes.string,
  };

  static defaultProps = {
    type: "item",
  };

  renderErrorMessage() {
    return `There was an error loading your selections.
      Check your network connection, then `;
  }

  renderEmptyMessage() {
    return `There are no ${this.props.type}s that match your selection. `;
  }

  render() {
    const { type } = this.props;
    const className = "m-entity-block l-container-wide a-bg-light-beige";
    const firstLetter = this.props.type === "error" ? "p" : "P";

    return (
      <CSSTransition in={true} timeout={200} classNames="react-appear-" appear>
        <div className={className}>
          <p className={"t-copy-bold t-align-center a-color-neutral-75"}>
            {type === "error"
              ? this.renderErrorMessage()
              : this.renderEmptyMessage()}
            {`${firstLetter}lease try again.`}
          </p>
        </div>
      </CSSTransition>
    );
  }
}
