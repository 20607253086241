import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class ExhibitionListYearPagination extends PureComponent {
  static displayName = "ExhibitionList.YearPagination";

  static propTypes = {
    years: PropTypes.arrayOf(PropTypes.number).isRequired,
    currentYear: PropTypes.number.isRequired,
    handleClick: PropTypes.func.isRequired,
  };

  get hasPrevYear() {
    const { years, currentYear } = this.props;

    return currentYear !== years[years.length - 1];
  }

  get hasNextYear() {
    const { years, currentYear } = this.props;

    return currentYear !== years[0];
  }

  get prevYear() {
    const { years, currentYear } = this.props;
    const active = years.indexOf(currentYear);
    return years[active + 1];
  }

  get nextYear() {
    const { years, currentYear } = this.props;
    const active = years.indexOf(currentYear);
    return years[active - 1];
  }

  renderButton(direction) {
    const label = direction.charAt(0).toUpperCase() + direction.slice(1);
    return (
      <button
        className="m-pagination__link"
        onClick={(event) => {
          const value =
            direction === "previous" ? this.prevYear : this.nextYear;
          this.props.handleClick(value);
        }}
      >
        <h2>{label}</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
          className={classNames("a-icon", {
            "m-pagination__back-arrow": direction === "previous",
            "m-pagination__next-arrow": direction === "next",
          })}
          aria-hidden="true"
          transform={direction === "previous" ? "rotate(180)" : ""}
        >
          {/* eslint-disable-next-line max-len */}
          <path d="M22.905 14.665L17.14 8.928l1.878-1.869L28 16l-8.983 8.941-1.878-1.869 5.767-5.738H4v-2.669z" />
        </svg>
      </button>
    );
  }

  render() {
    return (
      <div className="m-pagination">
        {this.hasPrevYear && this.renderButton("previous")}
        {this.hasNextYear && this.renderButton("next")}
      </div>
    );
  }
}
