import Percolator from "../../helpers/Percolator";
import Sb from "../abstract/StatefulBehavior";
import Flickity from "flickity";
import classNames from "classnames";
import ObjectFit from "./ObjectFit";

export default class SlideCards extends Sb {
  constructor(el, props, refs) {
    super();

    const percolator = new Percolator();

    this.state = {
      active: 0,
    };

    this.el = el;
    this.refs = refs;
    this.props = props;

    this.start = null;

    this.requestId = null;

    // Setup equal heights for flickity
    Flickity.prototype._createResizeClass = function () {
      this.element.classList.add("flickity-resize");
    };

    Flickity.createMethods?.push("_createResizeClass");

    const resize = Flickity.prototype.resize;
    Flickity.prototype.resize = function () {
      this.element.classList.remove("flickity-resize");
      resize.call(this);
      this.element.classList.add("flickity-resize");
    };

    // optionally allow running slider behavior on a ref; otherwise use `el`
    const sliderEl = refs.slider ? refs.slider : el;

    // optional props to control slider appearance/functionality
    const {
      autoplay = false,
      hidebuttons = false,
      wraparound = false,
      cssgallerysize = false,
      objectfit = false,
      adaptiveheight = false,
      contain = false,
    } = props;
    props.duration = props.duration ? props.duration : 4000;
    props.initTextClass = refs.text ? refs.text[0].className : null;

    // optionally instantiate ObjectFit behavior on slides
    // ensures behavior has run prior to Flickity instantiation so that
    // Flickity's inline styles on slides aren't overridden
    if (props.objectfit) {
      this.images = [...sliderEl.children].map((image, index) => {
        return percolator.loadBehavior(image, ObjectFit);
      });
    }

    this.slider = new Flickity(sliderEl, {
      cellAlign: "left",
      selectedAttraction: 0.075,
      friction: 0.48,
      pageDots: false,
      prevNextButtons: !hidebuttons,
      wrapAround: wraparound,
      setGallerySize: !cssgallerysize,
      adaptiveHeight: adaptiveheight,
      contain: contain,
      lazyLoad: this.lazyLoad,
      arrowShape:
        "M41.615 51.605l6.934 6.9-2.259 2.248L35.488 50 46.29 39.247l2.259 2.248-6.936 6.901h22.738v3.21z", // eslint-disable-line
    });

    this.update();
    this.bindEvents();
  }

  update = () => {
    if (!this.props.autoplay) return false;

    this.slider.select(this.state.active);

    this.refs.progressBar.forEach((bar, index) => {
      this.setProgressValue(bar, 0);

      if (index === this.state.active) {
        this.requestId = window.requestAnimationFrame(
          this.increaseProgress(this.start, bar, index)
        );
      }
    });

    this.refs.text && this.updateTextOverlay();
  };

  // update state based on drag event (only used when props.autoplay is true)
  bindEvents() {
    if (!this.props.autoplay) return false;

    this.slider.on("dragEnd", () => {
      window.cancelAnimationFrame(this.requestId);
      this.setState({ active: this.slider.selectedIndex });
    });
  }

  increaseProgress(start, element, index) {
    return (timestamp) => {
      if (!start) start = timestamp;
      const progress = ((timestamp - start) / this.props.duration) * 100;

      this.setProgressValue(element, progress);

      if (progress < 100) {
        this.requestId = window.requestAnimationFrame(
          this.increaseProgress(start, element, index)
        );
      } else {
        this.setState({
          active: index === this.slideCount - 1 ? 0 : index + 1,
        });
      }
    };
  }

  updateTextOverlay() {
    if (this.refs.text.length <= 1) return false;

    const initClass = this.props.initTextClass;
    [...this.refs.text].forEach((el, index) => {
      el.className = classNames(initClass, {
        [`${initClass}--active`]: index === this.state.active,
        [`${initClass}--inactive`]: index !== this.state.active,
      });
    });
  }

  setProgressValue(ref, value) {
    ref.value = Math.floor(value);
    ref.innerHTML = `${Math.floor(value)}%`;
  }

  get slideCount() {
    return this.slider.slides.length;
  }

  get lazyLoad() {
    const { lazyload } = this.props;

    if (!lazyload) return false;

    if (lazyload === "true") return true;

    return parseInt(lazyload, 10);
  }
}
