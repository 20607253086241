import Sb from "../abstract/StatefulBehavior";
import camelCase from "lodash/camelCase";

export default class RegisterWaypoints extends Sb {
  static behaviorName = "RegisterWaypoints";

  constructor(el, props) {
    super();

    this.el = el;
    this.props = props;

    this.dataAttr = "[data-anchor-id]";
    this.anchorArray = this.nodeListToArray(this.dataAttr);
    this.scrolling = false;
    this.waypoints = this.getWaypoints();

    if (!this.waypoints || !this.waypoints.length || this.isUnsupportedBrowser)
      return;

    this.state = {
      scrollY: window.scrollY,
    };

    this.registerAnchorEls();
    this.bindScroll();
  }

  get isUnsupportedBrowser() {
    return (
      !document.documentElement.getAttribute("style") ||
      /Edge\/\d./i.test(navigator.userAgent)
    );
  }

  registerAnchorEls = () => {
    this.props.dispatch({
      type: "REGISTER_WAYPOINTS",
      waypoints: this.waypoints,
    });
  };

  bindScroll() {
    window.addEventListener("scroll", (e) => {
      if (!this.scrolling) {
        window.requestAnimationFrame(() => {
          const scrollDirection =
            window.scrollY > this.state.scrollY ? "down" : "up";
          this.updateActive(scrollDirection);
          this.setState({ scrollY: window.scrollY }, null);
          this.scrolling = false;
        });

        this.scrolling = true;
      }
    });
  }

  getWaypoints() {
    const refArray = this.anchorArray.map((anchor) => {
      const titleProp = "propsTitle";

      const id = anchor.dataset[this.attrToKey(this.dataAttr)];
      const title = anchor.dataset[titleProp];

      return {
        id: id,
        title: title,
      };
    });

    return refArray;
  }

  updateActive = (direction) => {
    const anchorsLength = this.anchorArray.length;

    // get height values of offset elements
    const navMenuHeight = parseInt(
      window
        .getComputedStyle(document.documentElement)
        .getPropertyValue("--waypoint-nav-height"),
      10
    );
    const headerHeight = parseInt(
      window
        .getComputedStyle(document.documentElement)
        .getPropertyValue("--header-height"),
      10
    );
    // site header is present on scroll up,
    // so set offset based on scroll direction
    const offset =
      direction === "down" ? navMenuHeight : navMenuHeight + headerHeight;

    // find the first available waypoint which user hasn't scrolled past yet
    // /factoring in offset plus a few extra pixels
    const current = this.anchorArray.findIndex((anchor) => {
      const yPos = anchor.getBoundingClientRect().y - 5;
      return yPos - offset > 0;
    });

    // shift active value by 1, since waypoint should be active
    // until bottom of section (i.e. at next waypoint)
    // …but if current = -1, set active to length of array
    const active = current >= 0 ? Math.max(0, current - 1) : anchorsLength - 1;

    // dispatch active waypoint to store
    this.props.dispatch({
      type: "UPDATE_ACTIVE_WAYPOINT",
      active: active,
    });
  };

  nodeListToArray(attr) {
    const elArray = Array.from(document.querySelectorAll(attr));

    return elArray;
  }

  attrToKey(attr) {
    const dataAttr = attr.match(/\[data-([-\w]+)/)[1];

    return camelCase(dataAttr);
  }
}
