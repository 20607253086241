import Sb from "../abstract/StatefulBehavior";
import classNames from "classnames";
import hoverintent from "hoverintent";

export default class OpenImageGridSlideshow extends Sb {
  static behaviorName = "OpenImageGridSlideshow";

  constructor(el, props, refs) {
    super();

    this.state = {
      hovering: false,
    };

    this.el = el;
    this.props = props;
    this.refs = refs;

    this.props.initElClass = el.className;

    this.registerElementOffset();
    this.bindEvents();
  }

  update = () => {
    this.el.className = classNames(this.props.initElClass, {
      "is-hovering": this.state.hovering,
    });
  };

  registerElementOffset = () => {
    const elOffset = this.el.getBoundingClientRect().top;

    this.props.dispatch({
      type: "REGISTER_ELEMENT_OFFSET",
      imageOffsets: {
        id: this.props.id,
        YOffset: elOffset,
      },
    });
  };

  bindEvents = () => {
    this.el.addEventListener("click", this.handleClick);
    this.el.addEventListener("keyup", this.handleKeyUp);

    hoverintent(
      this.el,
      () => {
        this.setState({ hovering: true });
      },
      () => {
        this.setState({ hovering: false });
      }
    ).options({
      interval: 35,
    });
  };

  handleClick = (event) => {
    this.props.dispatch({
      type: "OPEN_SLIDESHOW",
      current: this.props.id,
    });
  };

  handleKeyUp = (event) => {
    event.preventDefault();

    // Enter
    if (event.keyCode !== 13 && event.key !== "Enter") return;

    this.props.dispatch({
      type: "OPEN_SLIDESHOW",
      current: this.props.id,
    });
  };
}
