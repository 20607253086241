export default class AnimateLoading {
  constructor(el) {
    this.el = el;

    this.loaderTimeout = null;
    // store animation GIF src attribute
    this.loaderImage = el.querySelector(`[data-ref="loaderImage"]`);
    this.imageSrc = this.loaderImage.dataset.src;

    this.updateTop();
    // remove GIF src (and again when loaded() is invoked)
    // Will add it back whenever loading() is invoked …
    // … this allows GIF to start at the first frame each time
    this.loaderImage.src = "";
  }

  loading = () => {
    clearTimeout(this.loaderTimeout);
    this.loaderImage.src = this.imageSrc;
    // delay loader actions so animation isn't too eager to show
    this.loaderTimeout = setTimeout(() => {
      !this.el.classList.contains("is-visible") &&
        this.el.classList.add("is-visible");

      this.updateTop();
    }, 500);
  };

  loaded = () => {
    clearTimeout(this.loaderTimeout);

    this.el.classList.contains("is-visible") &&
      this.el.classList.remove("is-visible");

    this.loaderTimeout = setTimeout(() => {
      this.loaderImage.src = "";
    }, 500);
  };

  updateTop() {
    const y = document.documentElement.style.getPropertyValue(
      "--content-wrapper-top"
    );
    this.el.setAttribute(
      // set twice, once with fallback for browsers w/o custom property support
      "style",
      `top: 430px; top: var(--content-wrapper-top, ${y});`
    );
  }
}
