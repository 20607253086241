import Sb from "../abstract/StatefulBehavior";
import debounce from "lodash/debounce";

export default class ToggleTab extends Sb {
  constructor(el, props, refs) {
    super();
    this.state = {
      active: 0,
      markerWidth: 0,
      markerLeft: 0,
      contentHeight: "auto",
      contentMaxHeight: "1171px",
    };

    this.el = el;
    this.refs = refs;
    this.props = props;

    this.bindEvents();
    this.buildMarker();
    this.setContentHeight();
  }

  update = () => {
    const marker = this.refs.tabMarker;
    const activeTab = this.refs.tabContent[this.state.active];

    Object.assign(marker.style, {
      width: this.state.markerWidth,
      left: this.state.markerLeft,
    });

    Object.assign(this.refs.tabsContainer.style, {
      height: this.state.contentHeight,
      maxHeight: this.state.contentMaxHeight,
    });

    activeTab.classList.add("m-tabbed-content__level");
  };

  bindEvents() {
    const { openTab } = this.refs;
    openTab.forEach((tab, index) => {
      tab.addEventListener("click", this.switchTab);
    });
    this.debouncedDimensions = debounce(this.updateDimensions, 200);
    window.addEventListener("resize", this.debouncedDimensions);
  }

  switchTab = (event) => {
    event.preventDefault();
    const navIndex = event.target.getAttribute("index") - 1;
    const startingTab = this.refs.tabContent[this.state.active];
    startingTab.classList.remove("m-tabbed-content__level");

    // set new index and unset initial max-height, which limits the container
    // for cases where a flickity slider is in the first tab and the content
    // height gets miscalculated on first paint
    this.setState({
      active: navIndex,
      contentMaxHeight: "none",
    });
    this.updateDimensions();
  };

  updateDimensions = () => {
    this.buildMarker();
    this.setContentHeight();
  };

  setContentHeight = () => {
    const tab = this.refs.tabContent[this.state.active];
    const tabHeight = tab.getBoundingClientRect().height;
    const contentHeight = tabHeight + "px";
    this.setState({ contentHeight });
  };

  buildMarker = () => {
    const b = this.refs.openTab[this.state.active];
    const parentLeft = b.parentNode.getBoundingClientRect().left;
    const markerWidth = b.offsetWidth + "px";
    const rect = b.getBoundingClientRect();
    const markerLeft = rect.left - parentLeft + "px";

    this.setState({
      markerWidth,
      markerLeft,
    });
  };
}
