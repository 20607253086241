export default class ScrollBelow {
  constructor(el, props, refs) {
    this.el = el;
    this.refs = refs;
    this.props = props;

    this.bindClick();
  }

  bindClick() {
    const scrollTrigger = this.refs.scrollTrigger;
    scrollTrigger.addEventListener("click", this.scrollOnClick);
  }

  scrollOnClick = () => {
    const target = this.el.getBoundingClientRect().height;

    window.scrollTo({
      top: target + 1,
      left: 0,
      behavior: "smooth",
    });
  };
}
