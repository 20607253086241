// mapbox-gl is a 1MB bundle, and the component RenderMap is not used frequently
// import mapbox-gl as a separate chunk to reduce overall js bundle size
export default class RenderMap {
  constructor(el, props, refs) {
    this.el = el;
    this.props = props;

    // eslint-disable-next-line import/no-webpack-loader-syntax
    import(/* webpackChunkName: "mapboxgl" */ "!mapbox-gl").then(
      ({ default: mapboxgl }) => {
        this.renderMap(mapboxgl);
      }
    );
  }

  renderMap = (mapboxgl) => {
    const token =
      "pk.eyJ1IjoicHNhcnRtdXNldW0iLCJhIjoiY2pzMmNqbGV1MjNleTQ5bHY1dG5rbHBzbiJ9.jOjdsEHg7nU7LR9RcSRgFA"; // eslint-disable-line max-len
    mapboxgl.accessToken = token;

    const locations = [
      {
        name: "Palm Springs Art Museum",
        lat: 33.824232,
        long: -116.54989,
      },
      {
        name: "Palm Springs Art Museum in Palm Desert",
        lat: 33.72357,
        long: -116.39931,
      },
      {
        name: "Palm Springs Art Museum Architecture and Design Center",
        lat: 33.819163,
        long: -116.5464,
      },
    ];

    if (!mapboxgl.supported()) {
      return null;
    } else {
      this.currentLocation =
        locations[this.props.locationid - 1] || locations[0];

      this.map = new mapboxgl.Map({
        container: this.el,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [this.currentLocation.long, this.currentLocation.lat],
        zoom: 15,
      });

      this.map.on("load", () => {
        this.renderMarker(mapboxgl);
      });
    }
  };

  renderMarker(mapboxgl) {
    const geojson = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [this.currentLocation.long, this.currentLocation.lat],
          },
          properties: {
            title: "Mapbox",
            description: this.currentLocation.name,
          },
        },
      ],
    };

    // add markers to map
    geojson.features.forEach((marker) => {
      // create an HTML element for each feature
      const el = document.createElement("div");
      el.className = "m-map__marker";

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates)
        .addTo(this.map);
    });
  }
}
