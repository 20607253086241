import EventList from "./EventList/Wrapper";
import ExhibitionList from "./ExhibitionList/Wrapper";
import ImageGridSlideshow from "./ImageGridSlideshow";
import Slideshow from "./Slideshow";
import SlideshowCallout from "./SlideshowCallout/Wrapper";
import WaypointNavigation from "./WaypointNavigation";
import ContentTabs from "./ContentTabs/Wrapper";

export default {
  EventList,
  ExhibitionList,
  ImageGridSlideshow,
  Slideshow,
  SlideshowCallout,
  WaypointNavigation,
  ContentTabs,
};
